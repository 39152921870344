import React, { useEffect, useState } from 'react';
import app from '../firebase';
const db = app.firestore();

function Converter() {

    const [fileName, setFileName] = useState('');
    const [equipos, setEquipos] = useState([])


    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
    };

    const convertToJson = () => {
        try {
            // Función para eliminar acentos y caracteres especiales
            const quitarAcentos = (cadena) => {
                return cadena.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            };

            // Convierte los datos de equipos en objetos XML
            const xmlData = equipos.map((equipo) => ({
                loc: `https://coilinstrumentacion.com/${quitarAcentos(equipo.identificadorEquipo)}`,
                lastmod: equipo.fechaHora,
                changefreq: 'weekly', // Puedes ajustar esto según tus necesidades
                priority: 0.8, // Puedes ajustar esto según tus necesidades
            }));

            // Convierte los objetos XML en una cadena XML
            const xmlString = xmlData.map((equipo) => {
                return `
              <url>
                <loc>${equipo.loc}</loc>
                <lastmod>${equipo.lastmod}</lastmod>
                <changefreq>${equipo.changefreq}</changefreq>
                <priority>${equipo.priority}</priority>
              </url>
            `;
            }).join('');

            // Agrega la estructura XML completa
            const finalXml = `
            <?xml version="1.0" encoding="UTF-8"?>
            <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
              ${xmlString}
            </urlset>
          `;

            // Descargar el archivo XML generado
            const blob = new Blob([finalXml], { type: 'application/xml' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xml`;
            a.click();
            window.URL.revokeObjectURL(url);

            // Envía los datos JSON al servidor para generar el sitemap
        } catch (error) {
            console.error('Error al analizar los datos JSON', error);
        }
    };




    useEffect(() => {

        db.collection("equipos")
            .orderBy("nombre", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = [];
                const auxPrecios = [];
                const docsSinOrden = [];

                querySnapshot.forEach((doc) => {
                    const data = { ...doc.data(), id: doc.id };
                    if (typeof data.orden === 'number') {
                        docs.push(data); // Registros con campo "orden" numérico
                        auxPrecios.push(doc.data().precio);
                    } else {
                        docsSinOrden.push(data); // Registros sin campo "orden" o no numérico
                    }
                });

                // Ordenar registros con campo "orden" de menor a mayor
                docs.sort((a, b) => (a.orden || 0) - (b.orden || 0));

                // Combinar registros ordenados y registros sin orden
                const equiposOrdenados = [...docs, ...docsSinOrden];

                setEquipos(equiposOrdenados);
            });

    }, [])
    return (
        <div>
            <h1>Convertir JSON a XML (Sitemap)</h1>

            <input
                type="text"
                placeholder="Nombre del archivo XML"
                value={fileName}
                onChange={handleFileNameChange}
            />
            <button onClick={convertToJson}>Generar Sitemap XML</button>
        </div>
    );
}

export default Converter;
