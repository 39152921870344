import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function NotFound() {
    return (
        <main>
            <Header></Header>


            <div className="container-fluid bg-light-gray d-flex justify-content-center flex-column align-items-center">
                <div className="not-found-bg my-4"></div>
                <div className="my-4">
                    <span className="display-6 fw-bold my-2">Error 404 | Página no encontrada</span>
                    <p className=' text-center my-2'>La página que buscas aún no está disponible</p>
                </div>
                <div className="my-4">
                    <Link to="/" className='btn btn-dark'>Ir al inicio ➤➤ </Link>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}
