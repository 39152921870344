import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import Header from '../../components/Header'


// Firebase
import firebase from 'firebase/compat/app';
import app from '../../firebase';
const db = app.firestore();
export default function Landing() {


    const navigation = useNavigate()
    const params = useParams()


    const [state, setState] = useState({})
    const [landData, setLandData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    const sendInformation = () => {

        state["fechaHora"] = timenow()
        state["landing"] = params.landId
        db.collection("solicitudesLandingPages").add(state).then(() => {
            window.location.reload()
            alert("Recibimos tu información, gracias")
        }).catch("Hubo un error en el sistema")
    }

    useEffect(() => {
        db.collection("landingPages")
            .where("url", "==", params.landId)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    // Si no hay documentos en la colección que coincidan con el ID de la URL, redirige a una página 404
                    navigation("/404");
                    return;
                }
                querySnapshot.docs.forEach((doc) => {
                    setLandData({ ...doc.data(), id: doc.id });
                    setIsLoading(false);
                });
            });
    }, []);

    return (
        <div>
            <Header activeLink="inicio" />
            <title>{landData.titulo}</title>
            {
                isLoading == false ?
                    <div className="container-fluid p-4 bg-blue-marin minHeight-100">
                        <div className="row row-cols-2">
                            <div className="col-12 col-md-6  bg-light  rounded h-100">
                                <div className="my-4 text-center">
                                    <h1 className='text-dark fw-bold'>{landData.titulo}</h1>
                                </div>
                                <div className="my-3">
                                    <div className="form">
                                        <div className="form-group my-2">
                                            <input type="text" onChange={(e) => handleTextChange("nombreCompleto", e.target.value)} className="form-control" placeholder='Nombre completo' />
                                        </div>
                                        <div className="form-group my-2">
                                            <input type="email" onChange={(e) => handleTextChange("email", e.target.value)} className="form-control" placeholder='Email' />
                                        </div>
                                        <div className="form-group my-2">
                                            <input type="text" onChange={(e) => handleTextChange("ubicacion", e.target.value)} className="form-control" placeholder='Ubicación' />
                                        </div>
                                        <div className="form-group my-2">
                                            <input type="text" onChange={(e) => handleTextChange("telefono", e.target.value)} className="form-control" placeholder='Telefono' />
                                        </div>
                                        <div className="my-2">
                                            <select class="form-select" onChange={(e) => handleTextChange("formaPago", e.target.value)}>
                                                <option selected>Seleccionar</option>
                                                <option value="Financiamiento Directo">Financiamiento Directo</option>
                                                <option value="Pago de Contado">Pago de Contado</option>
                                                <option value="Arrendamiento">Arrendamiento</option>
                                            </select>
                                        </div>
                                        <div className="my-4 d-flex justify-content-center">
                                            <button className='btn btn-primary fs-5 text-light' onClick={() => sendInformation()}>Enviar información</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 justify-content-center d-flex">
                                <img src={landData.imageUrl} className="img-landing" alt={"alt-img"} />
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="my-4">
                            <div className="d-flex justify-content-center align-items-center minHeight-100">
                                <div className="spinner-border text-dark" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Footer />
        </div>
    )
}
