import React from 'react'
import './styles.css'
export default function ActionCardAdmin({ title, link, number, icon }) {
    return (
        <a href={link} className='action-card my-4'>
            <div className="my-2">
                {
                    icon
                }
                <span className='display-6 fw-bold'>{number} {title}</span>
            </div>
        </a>
    )
}
