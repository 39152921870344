import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
export default function FormularioCitas() {

    const sendMessage = (e) => {
        e.preventDefault();
        if (state.name !== undefined && state.email !== undefined) {
            if (a + b == result) {
                setIsLoading(true)
                e.preventDefault();
                emailjs.send('service_gmail', 'template_0hoeef8', state, 'v2aYf4x3Dbbtd-BWj')
                    .then((response) => {
                        alert("Haz agendado una reunión con nosotros, te enviaremos los detalles de la reunión en unos instantes.")
                        setIsLoading(false)
                        window.location.reload()
                    }, (err) => {
                        console.log('FAILED...', err);
                    });

            } else {
                console.log(a, b, result)
                alert("La suma no es correcta")
            }
        } else {
            alert("Por favor rellena todos los campos")
        }
    }

    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeText = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const [a, setA] = useState(0)
    const [b, setB] = useState(0)

    const [result, setResult] = useState(0)


    const handleSuma = (value) => {
        setResult(Number(value))
    }


    useEffect(() => {
        setA(Math.floor(Math.random() * 20) + 1)
        setB(Math.floor(Math.random() * 10) + 1)
    }, [])

    return (
        <section>
            <div className="container p-2">
                <form className='form my-4'>
                    <div className="d-flex my-2">
                        <input onChange={(e) => handleChangeText("name", e.target.value)} type="text" className="transparent-border-input my-1" placeholder="Nombre completo"></input>
                    </div>
                    <div className="d-flex w-100 my-2">
                        <input onChange={(e) => handleChangeText("email", e.target.value)} type="email" className="transparent-border-input m-1" placeholder="Correo electrónico"></input>
                        <input onChange={(e) => handleChangeText("phone", e.target.value)} type="number" className="transparent-border-input m-1" placeholder="Teléfono"></input>
                    </div>
                    <div className="my-2 d-flex justify-content-center">
                        <input onChange={(e) => handleChangeText("company", e.target.value)} type="text" className="transparent-border-input m-1" placeholder="Empresa u Organización"></input>
                    </div>

                    <div className="my-4 justify-content-center text-start">
                        <label htmlFor="service">¿Cuándo quieres reunirte con nosotros? <label className='text-danger'>*</label></label>
                        <input className='transparent-border-input m-1' onChange={(e) => handleChangeText("fechaHoraReunion", e.target.value)} type="datetime-local"></input>
                    </div>

                    <div className="my-2 justify-content-center text-start">
                        <label htmlFor="service">Tipo de reunión <label className='text-danger'>*</label></label>
                        <select className='form-select form-select-rounded-transparent m-1' id="service" onChange={(e) => handleChangeText("tipoDeReunion", e.target.value)}>
                            <option value="" selected>Selecciona un servicio</option>
                            <option value="Por llamada">Por llamada</option>
                            <option value="Google Meet">Google Meet</option>
                            <option value="Zoom">Zoom</option>
                        </select>
                    </div>

                    <div className="my-4 justify-content-center text-start">
                        <label htmlFor="service">Comentarios adicionales</label>
                        <textarea className='transparent-border-textarea m-1' onChange={(e) => handleChangeText("comentarios", e.target.value)} rows={3} />
                    </div>

                    <div className="my-3 d-flex justify-content-center">
                        <input onChange={(e) => handleSuma(e.target.value)} type="text" className="w-100 transparent-border-input" placeholder={`¿Cuánto es?: ${a} + ${b}`}></input>
                    </div>
                    <div className='my-2 d-flex justify-content-center'>
                        {
                            isLoading == false ?
                                <button onClick={(e) => sendMessage(e)} className='w-100 btn btn-solicitar-info' style={{ height: '50px' }}>Agendar reunión</button>
                                :
                                <button className="w-100 btn btn-more-info" disabled>
                                    <span className="spinner-border text-light" role="status" aria-hidden="true"></span>
                                </button>
                        }
                    </div>
                </form>
            </div>
        </section>
    )
}
