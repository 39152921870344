import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import HeroMobile from '../components/HeroMobile'
import HeroPc from '../components/HeroPc'
import Carrusel from '../components/Carrusel/Carrusel'
import Footer from '../components/Footer'

// Firebase
import firebase from 'firebase/compat/app';
import app from '../firebase';
import WaFloatButton from '../components/WaFloatButton'
import Hero from '../components/Hero'
import PorQueElegirnos from '../components/Porqueelegirnos'
import ProductosDestacados from '../components/productosDestacados/ProductosDestacados'
import Testimonios from '../components/testimonios/Testimonios'
import { Link } from 'react-router-dom'
import FormularioContacto from '../components/FormularioContacto'
const db = app.firestore();

export default function Inicio() {

    const [isLoading, setIsLoading] = useState(false)
    const [marcas, setMarcas] = useState([])

    useEffect(() => {
        setIsLoading(true)
        db.collection("marcas")
            .orderBy("nombre", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = [];
                const docsSinOrden = [];

                querySnapshot.docs.forEach((doc) => {
                    const data = { ...doc.data(), id: doc.id };
                    if (typeof data.orden === 'number') {
                        docs.push(data); // Registros con campo "orden" numérico
                    } else {
                        docsSinOrden.push(data); // Registros sin campo "orden" o no numérico
                    }
                });

                // Ordenar registros con campo "orden" de menor a mayor
                docs.sort((a, b) => (a.orden || 0) - (b.orden || 0));

                // Combinar registros ordenados y registros sin orden
                const marcasOrdenadas = [...docs, ...docsSinOrden];

                setMarcas(marcasOrdenadas);
                setIsLoading(false);
            });
    }, [])




    return (

        <body>

            {
                isLoading == true ?

                    <div className="d-flex justify-content-center align-items-center minHeight-100">
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    :

                    <main>
                        <Header activeLink="inicio"></Header>

                        <WaFloatButton></WaFloatButton>

                        <div id="pc">
                            <HeroPc
                                showButton={true} to={"/equipos"}
                                important="Venta de equipos Industriales"
                                description="Contamos con un amplio catálogo de productos y las mejores marcas disponibles"
                                alternative="Entregas seguras e inmediatas en todo México"
                                classHeroPage="heroPage"
                                imageUrl={"https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fhero-banner-inicio-pc-_1_.webp?alt=media&token=8c177a11-c685-482f-ac30-94909645be64"}
                                buttonText="Compra un equipo ➤➤">
                            </HeroPc>
                        </div>

                        <div id="mb">
                            <HeroMobile
                                showButton={true} to={"/contacto"}
                                important="Servicios especializados"
                                description={"Contamos con equipo capacitado para ofrecerte lo mejor en nuestros servicios."}
                                classHeroPage="heroPage"
                                imageUrl={"https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fthisisengineering-raeng-WjOWazUPAss-unsplash.jpg?alt=media&token=9856cf62-dbc3-4d60-9a94-1b3a4ab73b66"}
                                buttonText="Contáctanos ➤➤">
                            </HeroMobile>
                        </div>


                        <ProductosDestacados />


                        <div className="container-fluid red-gradient py-4">
                            <div className="my-4 d-flex justify-content-center">
                                <img style={{width:"250px"}} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fstore-open-svgrepo-com.svg?alt=media&token=4c073b35-c11f-4c9b-8d89-18ec476d6469" />
                            </div>
                            <div className="my-2">
                                <p className='fs-4 text-white fw-bold text-center'>Ahora puedes comprar cualquier producto directamente en nuestra Tienda en Línea</p>
                            </div>

                            <div className="my-2">
                                <p className='fs-4 text-white text-center'>Pagos 100% seguros y envíos inmediatos a todo el país</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href="https://shop.coilinstrumentacion.com" className='btn fs-3 btn-more-info my-4'>
                                    Comprar en línea ahora
                                </a>
                            </div>
                        </div>

                        <div className="banner-facturacion"></div>

                        <section className='container-fluid p-4 bg-white' style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLines-background.png?alt=media&token=c2a8117d-18ac-423c-84f0-75a4f85aa5b3)` }}>

                            <h1 id='pc' className="text-danger fw-bold">Distribuidores oficiales<span className='text-dark'> de las mejores marcas</span></h1>
                            <h4 id='mb' className="text-danger fw-bold">Distribuidores oficiales<span className='text-dark'> de las mejores marcas</span></h4>

                            <Carrusel logos={marcas.slice(0, 8)} />

                            <div className="my-4 d-flex justify-content-center">
                                <a id='pc' href="/marcas" className='w-50 btn btn-solicitar-info'>
                                    Conoce todas las marcas  ➤
                                </a>

                                <a id='mb' href="/marcas" className='w-100 btn btn-solicitar-info'>
                                    Conoce todas las marcas  ➤
                                </a>
                            </div>
                        </section>

                        <div className="banner-pagos"></div>


                        <div class="mt-4 container d-flex justify-content-center flex-column">
                            <h1 id='pc' className="text-danger fw-bold">Solicita una llamada <span className='text-dark'>con un agente de ventas</span></h1>
                            <h3 id='mb' className="text-danger fw-bold">Solicita una llamada <span className='text-dark'>con un agente de ventas</span></h3>
                            <div className='text-center'>
                                <span class="text-dark fs-6">Rellena el formulario a continuación y te contactaremos a la brevedad, nos encanta atender tu llamada</span>
                            </div>
                        </div>

                        <div className="container bg-transparent">
                            <div className="row row-cols-2">
                                <div className="col-12 col-md-6">
                                    <div className="book-consulting-img-service"></div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <FormularioContacto />
                                </div>

                            </div>
                        </div>

                        <PorQueElegirnos />

                        <div className="petroil-photo"></div>
                        <Footer></Footer>
                    </main>
            }
        </body>
    )
}
