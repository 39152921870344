import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './styles.css'
import WaFloatButton from '../../components/WaFloatButton'
import Hero from '../../components/Hero'

import app from '../../firebase';
const db = app.firestore();

export default function Marcas() {

    const [marcas, setMarcas] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        db.collection("marcas")
            .orderBy("nombre", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = [];
                const docsSinOrden = [];

                querySnapshot.docs.forEach((doc) => {
                    const data = { ...doc.data(), id: doc.id };
                    if (typeof data.orden === 'number') {
                        docs.push(data); // Registros con campo "orden" numérico
                    } else {
                        docsSinOrden.push(data); // Registros sin campo "orden" o no numérico
                    }
                });

                // Ordenar registros con campo "orden" de menor a mayor
                docs.sort((a, b) => (a.orden || 0) - (b.orden || 0));

                // Combinar registros ordenados y registros sin orden
                const marcasOrdenadas = [...docs, ...docsSinOrden];

                setMarcas(marcasOrdenadas);
                setLoading(!loading);
            });

    }, [])

    return (
        <body>
            <main>
                <Header activeLink="marcas"></Header>

                <WaFloatButton></WaFloatButton>

                <Hero style={"marcas"} title={"Marcas"} description={"Nos orgullece presentarte las marcas con las que trabajamos, buscamos siempre ofrecerte la mejor calidad en todos nuestros productos y servicios, es por eso que nunca escatimamos en la seleccion de nuestros proveedores, marcas de clase mundial."} urlBackground={"https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fpedro-lastra-Nyvq2juw4_o-unsplash.jpg?alt=media&token=4e91fe59-f68e-498a-8f9c-9e5554661c03"} />

                <div className="my-4">
                    <div className="d-flex justify-content-center">
                        <h4 className='text-muted fw-bold fs-3 text-center'>Consideramos todos tus proyectos como nuestros propios proyectos.</h4>
                    </div>
                </div>
                <section className='container-fluid p-4 bg-white' style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLines-background.png?alt=media&token=c2a8117d-18ac-423c-84f0-75a4f85aa5b3)` }}>
                    <div className="row row-cols-3 justify-content-center">
                        {
                            !loading ?
                                marcas.map((marca, index) => (
                                    <div key={index} className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                                        <img src={marca.imagen} alt={marca.nombre} title={marca.nombre} />
                                    </div>
                                ))
                                :
                                <div className="d-flex justify-content-center align-items-center minHeight-100">
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                        }
                    </div>
                </section>

                <Footer></Footer>
            </main>
        </body>
    )
}
