import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Categorias() {
    let navigate = useNavigate()


    const [slides, setSlides] = useState([])


    const [urlSlideDestino, setUrlSlideDestino] = useState("")
    const [currentImage, setCurrentImage] = useState(null)
    const [imageTempSlidePreview, setImageTempSlidePreview] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [altSlide, setAltSlide] = useState("")


    // Upload slides
    const handleSliderImageChange = (e) => {
        setCurrentImage(e.target.files[0])
        setImageTempSlidePreview(window.URL.createObjectURL(e.target.files[0]))
    }

    const handleUploadSlide = () => {
        setIsUploading(true)

        const timestamp = firebase.firestore.FieldValue.serverTimestamp();


        const uploadTask = firebase.storage().ref(`slides/${currentImage.name}`).put(currentImage);

        uploadTask.on("state_changed",
            (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        console.log("Upload is paused");
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        console.log("Upload is running");
                        break;

                }
            }, (error) => {
                console.log("No fue posible cargar la imagen, código de error: ", error.code)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    db.collection("slides").add({
                        "fechaHora": timestamp,
                        url: downloadUrl,
                        urlDestino: urlSlideDestino,
                        altSlide: altSlide
                    }).then(() => {
                        alert("Slide cargado exitosamente")
                        window.location.reload()
                        setIsUploading(false)
                        setUrlSlideDestino("")
                        setAltSlide("")
                    }).catch((error) => {
                        setIsUploading(false)
                        alert("No fue posible cargar el Slide, error", error)
                        window.location.reload()

                    })
                    // }) DEMETRIO ESTA LOQUITO JAJA <POMPIS></POMPIS> <CABEZON></CABEZON>
                })
            }
        )
    }

    const deleteSlideById = (id) => {
        if (window.confirm("¿Eliminar el slide?")) {
            db.collection("slides").doc(id).delete().then(() => {
                alert("Slides eliminado")
            }).catch((error) => {
                alert("Error al eliminar el producto, código de error: ", error.message)
            })
        }
    }



    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }
        // Obtener slider
        db.collection("slides").orderBy("fechaHora", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setSlides(docs)
            })
    }, [])
    return (
        <div>
            <NavbarBack></NavbarBack>

            <div className="p-4">
                <div className="my-4">
                    <span className='display-6'>Slides</span>
                </div>


                <div className='d-flex flex-row-reverse'>
                    <button className='btn btn-green fs-12 text-light' data-bs-toggle="modal" data-bs-target="#modalNuevoSlide">Nueva &nbsp; <li className='fa fa-plus'></li>
                    </button>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Preview</th>
                            <th className='text-dark fs-12'>Url</th>
                            <th className='text-dark fs-12'>Texto alt</th>
                            <th className='text-dark fs-12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            slides.map((slide, index) => (
                                <tr key={index}>
                                    <td>
                                        <a href={slide.url} target={"_blank"}>
                                            <img src={slide.url} width="50" height={"auto"} />
                                        </a>
                                    </td>
                                    <td>
                                        <a href={slide.urlDestino} target={"_blank"} className="fs-12 text-dark">🔗 Ir a destino</a>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{slide.altSlide}</span>
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-danger fs-12" onClick={() => deleteSlideById(slide.id)}>
                                            <li className='fa fa-trash'></li>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Modal nueva categoría */}
            <div className="modal fade" id="modalNuevoSlide" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nuevo slide</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="my-1">
                                <div className="form-group">
                                    <input type="file" onChange={handleSliderImageChange} className='form-control fs-12-sm my-2' />
                                    <input type="text" placeholder='Url destino' className='form-control fs-12-sm my-2' onChange={(e) => setUrlSlideDestino(e.target.value)} />
                                    <input type="text" placeholder='Alt del slider' className='form-control fs-12-sm my-2' onChange={(e) => setAltSlide(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary fs-12" onClick={() => window.location.reload()} data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-outline-dark fs-12" data-bs-dismiss="modal" onClick={handleUploadSlide}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal nueva categoría */}
        </div>
    )
}
