import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HeroPc from '../components/HeroPc'
import HeroMobile from '../components/HeroMobile'
import WaFloatButton from '../components/WaFloatButton'
import { Link } from 'react-router-dom'

export default function Servicios() {
    return (
        <body>
            <WaFloatButton></WaFloatButton>
            <Header activeLink="servicios"></Header>
            <div id="pc">
                <HeroPc
                    showButton={true} to={"/equipos"}
                    important="Servicios especializados"
                    description="Contamos con equipo capacitado para ofrecerte lo mejor en nuestros servicios."
                    alternative="Estamos a tu disposición para ayudarte en cada paso del proceso."
                    classHeroPage="heroPage"
                    imageUrl={"https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/Banner%20servicios.png?alt=media&token=8ea6122b-d1f7-4eea-aedf-a2adab7fa4ae"}
                    buttonText="Compra un equipo ➤➤">
                </HeroPc>
            </div>

            <div id="mb">
                <HeroMobile
                    showButton={true} to={"/contacto"}
                    important="Servicios especializados"
                    description={"Contamos con equipo capacitado para ofrecerte lo mejor en nuestros servicios."}
                    classHeroPage="heroPage"
                    imageUrl={"https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/thisisengineering-raeng-omrpeqLz6Po-unsplash.jpg?alt=media&token=1597d006-fc08-4cb4-807a-a0b18285385b"}
                    buttonText="Contáctanos ➤➤">
                </HeroMobile>
            </div>

            <div className="container my-6">
                <div className="my-2">
                    <div className="container my-4">
                        <div className="servicios my-4">
                            <div className="container">
                                <div className="row row-cols-2">
                                    <div className="col-12 col-md-6">
                                        <div className="my-3">
                                            <h5 className='display-6 fw-bold text-start'>
                                                Servicios
                                            </h5>

                                            <ul>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Mantenimiento de válvulas de control, hidráulicas, mecánicas, etc.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Limpieza de pintura en ambientes marinos.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Desarmado de válvulas, cambios internos.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Pruebas de hermeticidad y funcionalidad (Incluido equipo calibrado por las normas correspondientes).</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Recubrimiento de vinilo (PCV) en cualquier instrumento o equipo que lo requiera.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Fabricación de anclas.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Certificados de Calibración ante la EMA.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-center">
                                        <div className="img-services shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="servicios my-4">
                            <div className="container">
                                <div className="row row-cols-2">
                                    <div className="col-12 col-md-6">
                                        <div className="my-3">
                                            <h5 className='display-6 fw-bold text-start'>
                                                Renta y venta de maquinaria y equipo pesado para construcción
                                            </h5>

                                            <ul>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Retroexcarvadoras</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Excarvadoras.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Rodillo compactador.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Tractores</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Camión de volteo.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Mini cargadores.</li>
                                                <li className='fs-6 text-start my-2'><li className="fa fa-dot-circle-o text-danger mx-2"></li>Vibrocompactador.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-center">
                                        <div className="img-machines shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-center">
                            <Link to={"/contacto"} className="btn btn-outline-white shadow my-4">Cotiza tu proyecto</Link>
                        </div>
                    </div>
                </div>
            </div>



            <Footer></Footer>
        </body>
    )
}
