import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Swal from "sweetalert2";
// Firebase
import app from '../../../firebase';
import { MarcasController } from './MarcasController';
const db = app.firestore();

export default function Marcas() {
    let navigate = useNavigate()


    const [marcas, setMarcas] = useState([])
    const [numberOfItems, setNumberOfItems] = useState(10)


    // Modal states
    const [stateMarca, setStateMarca] = useState({})



    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false)
    // Maneja el evento de cambio del input de archivo
    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };

    const handleMarcaTextChange = (name, value) => {
        setStateMarca({ ...stateMarca, [name]: value })
    }

    // Functions



    const deleteMarca = (id) => {

        if (window.confirm("¿Eliminar marca?")) {

            db.collection("categorias").doc(id).delete().then(() => {
                alert("Categoría eliminada")
            }).catch((error) => {
                alert("Error al eliminar la marca, código de error: ", error.message)
            })
        }
    }

    const handleUploadEquipos = () => {
        setLoading(true)
        MarcasController.save(file).then(() => {
            Swal.fire({
                title: 'Marcas cargadas',
                text: 'Se han cargado las marcas correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#1ab394',

            }).then(() => {
                setLoading(false)
            })
        }).catch((error) => {
            setLoading(false)
            Swal.fire({
                title: 'Hubo un error en el sistema',
                text: 'Código de error: ' + error,
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#1ab394',

            })
        })
    };

    const returnOptionsRange = () => {
        const registrosPorPagina = 10;
        const totalRegistros = marcas.length;
        const totalOpciones = Math.ceil(totalRegistros / registrosPorPagina);
        const opciones = [];

        for (let i = 0; i < totalOpciones; i++) {
            const valor = (i + 1) * registrosPorPagina;
            opciones.push(<option key={i} value={valor}>{valor}</option>);
        }

        return opciones;
    };

    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener marcas
        db.collection("marcas").orderBy("orden", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setMarcas(docs)
            })
    }, [])
    return (
        <div className='container-dashboard'>
            <div className="p-4 bg-white shadow" style={{ borderRadius: "20px" }}>
                <div className="my-4">
                    <span className='display-6'>Marcas</span>
                </div>
                <div className="my-4">
                    <Link className='text-dark btn-outline' to={"/admin/backoffice"}>Volver</Link>
                </div>
                {
                    file ?
                        !loading ?
                            <div className='d-flex flex-row-reverse'>
                                <button onClick={handleUploadEquipos} className='btn btn-green fs-12 text-light'>Cargar productos &nbsp; <li className='fa fa-upload'></li>
                                </button>
                            </div>
                            :
                            <div className='d-flex flex-row-reverse'>
                                <button className='btn btn-green disabled fs-12 text-light'>
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    Cargando productos
                                </button>
                            </div>

                        :
                        null
                }

                <div className='d-flex flex-row-reverse'>
                    <div className="form-group my-2 p-2 border rounded">
                        <label className='small fw-bold'>Cargar por excel</label>
                        <input type="file" id="file" onChange={handleFileChange} className="form-control" />
                    </div>
                </div>
                <div className="w-20">
                    <label className='xsmall'>Mostrando {numberOfItems} de {marcas.length} registros</label>
                    <select select class="form-select form-select-sm" onChange={(e) => setNumberOfItems(e.target.value)}>
                        {returnOptionsRange()}
                    </select>
                </div>

                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Nombre</th>
                            <th className='text-dark fs-12'>Orden</th>
                            <th className='text-dark fs-12'>Imagen url</th>
                            <th className='text-dark fs-12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            marcas.slice(0, numberOfItems).map((Marca) => (
                                <tr>
                                    <td>
                                        <span className="fs-12 text-dark">{Marca.nombre}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{Marca.orden}</span>
                                    </td>
                                    <td>
                                        <img src={Marca.imagen} style={{ width: "100px", height: "auto" }} />
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-danger fs-12" onClick={() => deleteMarca(Marca.id)}>
                                            <li className='fa fa-trash'></li>
                                        </button>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Modal nueva marca */}
            <div className="modal fade" id="modalNuevaMarca" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nueva marca</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Nombre de la marca*</label>
                                <input type="text" onChange={(e) => handleMarcaTextChange("nombre", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label>Slug*</label>
                                <input type="text" onChange={(e) => handleMarcaTextChange("imagen", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="my-2">
                                <span className='text-danger fs-12'>* Datos obligatorios</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary fs-12" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal nueva marca */}
        </div>
    )
}
