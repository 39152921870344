import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/Index.css'

export default function Header(props) {

    const returnCurrentActiveLink = () => {
        switch (props.activeLink) {

            case "inicio":
                return (

                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="nav-link active" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="text-white nav-link">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="text-white nav-link">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="text-white nav-link">Contacto</Link></li>
                    </ul>

                )

            case "productos":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="text-white nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="nav-link active">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="text-white nav-link">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="text-white nav-link">Contacto</Link></li>
                    </ul>
                )
            case "nosotros":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="text-white nav-link">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="text-white nav-link">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link active">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="text-white nav-link">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="nav-link" aria-current="page">Contacto</Link></li>
                    </ul>
                )
            case "contacto":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="text-white nav-link">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="text-white nav-link">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="text-white nav-link">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="nav-link active" aria-current="page">Contacto</Link></li>
                    </ul>
                )
            case "marcas":
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="text-white nav-link">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="text-white nav-link">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="nav-link active">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="nav-link" aria-current="page">Contacto</Link></li>
                    </ul>
                )

            default:
                return (
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/" className="text-white nav-link" aria-current="page">Inicio</Link></li>
                        <li className="nav-item"><Link to="/equipos" className="text-white nav-link">Equipos</Link></li>
                        <li className="nav-item"><Link to="/nosotros" className="text-white nav-link">Nosotros</Link></li>
                        <li className="nav-item"><Link to="/servicios" className="text-white nav-link">Servicios</Link></li>
                        <li className="nav-item"><Link to="/marcas" className="text-white nav-link">Marcas</Link></li>
                        <li className="nav-item"><Link to="/contacto" className="text-white nav-link">Contacto</Link></li>
                    </ul>
                )

        }
    }
    return (
        <>
            <header id='pc' className="bg-dark align-items-center d-flex flex-wrap justify-content-center py-3  border-bottom">
                <a href="/" className="d-flex align-items-center m-2 mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <div className='logo_container_nav_color'></div>
                </a>

                <ul className="nav nav-pills">
                    {
                        returnCurrentActiveLink()
                    }
                </ul>
            </header>


            <header id='mb' className='bg-dark py-4'>
                <div className="nav-container d-flex w-100 justify-content-between align-items-center px-4 py-2">
                    <Link to="/">
                        <div className="logo_container_nav_color"></div>
                    </Link>
                    <div>
                        <button className="btn text-light bg-danger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false">
                            <span className="text-white">Menú &nbsp;</span>
                            <i className='fa fa-bars'></i>
                        </button>
                    </div>
                </div>
                <div className="collapse" id="navbarToggleExternalContent">
                    <div className="bg-secondary-2 p-4">
                        <div className="items">
                            <div className='bborder-dark my-4'>
                                <Link to={"/"} className='text-white'>Inicio ➤</Link>
                            </div>
                            <div className='bborder-dark my-4'>
                                <Link to={"/equipos"} className='text-white'>Productos ➤</Link>
                            </div>
                            <div className='bborder-dark my-4'>
                                <Link to={"/servicios"} className='text-white'>Servicios ➤</Link>
                            </div>
                            <div className='bborder-dark my-4'>
                                <Link to="/nosotros" className='text-white'>Nosotros ➤</Link>
                            </div>
                            <div className='bborder-dark my-4'>
                                <Link to="/contacto" className='text-white'>Contacto ➤</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>


    )
}