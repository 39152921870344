import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Cotizaciones() {
    let navigate = useNavigate()



    const [cotizaciones, setCotizaciones] = useState([])
    const [currentCotizacion, setCurrentCotizacion] = useState({})
    const [currentImagesEquipoInteres, setCurrentImagesEquipoInteres] = useState([])
    const [isLoadingEquipoInteresData, setIsLoadingEquipoInteresData] = useState(false)
    const [currentEquipoInteres, setCurrentEquipoInteres] = useState({})




    const handleSetCurrentCotizacion = async (cotizacion) => {
        setCurrentCotizacion(cotizacion)
        setIsLoadingEquipoInteresData(true)
        await db.collection("equipos").doc(cotizacion.equipoId).get().then((doc) => {
            setCurrentEquipoInteres({ ...doc.data(), id: doc.id })
            setCurrentImagesEquipoInteres(doc.data().images)
            setIsLoadingEquipoInteresData(false)
        }).catch((error) => {
            console.log(error)
            alert("No fue posible cargar los equipos intente nuevamente, código de error: ", error)
        })
    }

    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener cotizaciones
        db.collection("cotizacionesEquipos").orderBy("fechaHora", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setCotizaciones(docs)
            })
    }, [])
    return (
        <div className='container-dashboard'>
            <div className="p-4 bg-white shadow" style={{ borderRadius: "20px" }}>
                <div className="my-4">
                    <span className='display-6'>Cotizaciones</span>
                </div>
                <div className="my-4">
                    <Link className='text-dark btn-outline' to={"/admin/backoffice"}>Volver</Link>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Fecha y hora</th>
                            <th className='text-dark fs-12'>Cliente</th>
                            <th className='text-dark fs-12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cotizaciones.map((cotizacion) => (
                                <tr>
                                    <td>
                                        <span className="fs-12 text-dark">{cotizacion.fechaHora}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{cotizacion.nombreCliente}</span>
                                    </td>
                                    <td>
                                        <button className="btn btn-green text-light fs-12"
                                            data-bs-toggle="modal" data-bs-target="#modalVerCotizacion"
                                            onClick={() => handleSetCurrentCotizacion(cotizacion)}>
                                            Ver detalles &nbsp; <li className='fa fa-info-circle'></li></button>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Modal ver cotizacion */}
            <div className="modal fade" id="modalVerCotizacion" tabindex="-1" aria-hidden="true">

                {
                    currentEquipoInteres !== undefined ?
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Detalles de la cotizacion</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="my-2 caracteristica-description">
                                        <label className='text-dark fs-12 fw-bold'>Fecha y hora de solicitud: </label>
                                        <span className='text-dark fs-12'>&nbsp;{currentCotizacion.fechaHora}</span>
                                    </div>
                                    <div className="my-2 caracteristica-description">
                                        <label className='text-dark fs-12 fw-bold'>Nombre del cliente: </label>
                                        <span className='text-dark fs-12'>&nbsp;{currentCotizacion.nombreCliente}</span>
                                    </div>
                                    <div className="my-2 caracteristica-description">
                                        <label className='text-dark fs-12 fw-bold'>Correo del cliente: </label>
                                        <a href={`mailto:${currentCotizacion.correoCliente}`} className='text-dark fs-12'>&nbsp;{currentCotizacion.correoCliente}</a>
                                    </div>
                                    <div className="my-2 caracteristica-description">
                                        <label className='text-dark fs-12 fw-bold'>Teléfono de contacto: </label>
                                        <span className='text-dark fs-12'>&nbsp;{currentCotizacion.telefonoCliente}</span>
                                    </div>
                                    <div className="my-2 caracteristica-description">
                                        <label className='text-dark fs-12 fw-bold'>Método de pago: </label>
                                        <span className='text-dark fs-12'>&nbsp;{currentCotizacion.metodoPago}</span>
                                    </div>


                                    {
                                        isLoadingEquipoInteresData == true ?
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border text-dark" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            :

                                            currentEquipoInteres.nombre !== "" ?

                                                <Link to={`/${currentEquipoInteres.categoriaNombre}/${currentEquipoInteres.identificadorEquipo}`} target="_blank" className="text-dark fade-div d-flex rounded p-1 cs-pointer shadow-hover">
                                                    <div className="my-2">
                                                        <img src={currentImagesEquipoInteres[0]} className="preview-equipo-cotizar" alt="" srcset="" />
                                                        <span className='m-1'>{currentEquipoInteres.nombre}</span>
                                                    </div>
                                                </Link>
                                                :
                                                <span>No se ha recuperado la información</span>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark fs-12" data-bs-dismiss="modal">Aceptar</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-dark" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }
            </div>
            {/* Fin Modal ver cotizacion */}
        </div>
    )
}
