import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/Index.css'

export default function HeroMobile(props) {
    return (
        <div>
            <div style={{
                backgroundImage: `url('${props.imageUrl}')`,
                backgroundSize: "cover",
                width: "100%",
                backgroundPosition: "top",
                display: "flex !important",
                backgroundRepeat:"no-repeat",
                alignItems: "center !important",
                padding: "2%",
                minHeight: "300px"
            }}></div>
            <div className="container-content">
                <h1 className="display-3 text-dark fw-bold my-2">{props.important}</h1>
                <h1 className="text-dark my-2">{props.name}</h1>
                <div className="my-4">
                    <span className="text-dark text-start fs-5">{props.description}</span>
                </div>
                {
                    props.showButton == true ?
                        <Link className="btn btn-more-info my-4" to={props.to}>{props.buttonText}</Link>
                        :
                        ""
                }
            </div>

        </div>
    )
}
