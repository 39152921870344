import { render } from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Pages
import Inicio from './screens/Inicio'
import Equipos from './screens/Equipos'
import Servicios from './screens/Servicios'
import Nosotros from './screens/Nosotros/Nosotros'
import EquipoVisualizer from './screens/EquipoVisualizer'
import GraciasCotizacion from './screens/GraciasCotizacion'
import NotFound from './screens/NotFound'
import Dashboard from './screens/backoffice/Dashboard'
import EquiposBack from './screens/backoffice/Equipos/Equipos'
import CategoriasBack from './screens/backoffice/Categorias/Categorias'
import ListadoSlider from './screens/backoffice/Slider/ListadoSlider'
import Financiamiento from './screens/Financiamiento'
import Login from './screens/backoffice/Login'
import Contacto from './screens/Contacto/Contacto'
import Landing from './screens/Landing/Landing'
import CotizacionesBack from './screens/backoffice/Cotizaciones/Cotizaciones'
import SolicitudesFinanciamiento from './screens/backoffice/SolicitudesFinanciamiento/SolicitudesFinanciamiento'
import LandingCreate from './screens/backoffice/LandingPages/Create'
import LandingSolicitudes from './screens/backoffice/LandingPages/Solicitudes'
import Listado from './screens/backoffice/LandingPages/Listado'
import Marcas from './screens/backoffice/marcas/Marcas'
import MarcasPage from './screens/marcas/Marcas'
import Converter from './screens/convertor'



const rootElement = document.getElementById("root")
const app = () => {


  render(
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Inicio />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='/equipos' element={<Equipos />} />
        <Route path='/equipos/:linkId' element={<EquipoVisualizer />} />
        <Route path='/servicios' element={<Servicios />} />
        <Route path='/financiamiento' element={<Financiamiento />} />
        <Route path='/confirmacion_cotizacion/:idCotizacion' element={<GraciasCotizacion />} />
        <Route path='/contacto' element={<Contacto />} />
        <Route path='/servicios' element={<Servicios />} />
        <Route path='/marcas' element={<MarcasPage />} />
        <Route path='/nosotros' element={<Nosotros />} />
        {/* <Route path='/convertor' element={<Converter />} /> */}

        {/* Backoffice */}
        <Route path='/admin/login' element={<Login />} />
        <Route path='/admin/backoffice' element={<Dashboard />} />
        <Route path='/admin/backoffice/equipos' element={<EquiposBack />} />
        <Route path='/admin/backoffice/marcas' element={<Marcas />} />
        <Route path='/admin/backoffice/slider' element={<ListadoSlider />} />
        <Route path='/admin/backoffice/cotizaciones' element={<CotizacionesBack />} />
        <Route path='/admin/backoffice/financiamiento/solicitudes' element={<SolicitudesFinanciamiento />} />
        <Route path='/admin/backoffice/landing/create' element={<LandingCreate />} />
        <Route path='/admin/backoffice/landing/solicitudes' element={<LandingSolicitudes />} />
        <Route path='/admin/backoffice/landing/listado' element={<Listado />} />





        {/* Dinamyc landing */}
        <Route path='/:landId' element={<Landing />} />

      </Routes>
    </BrowserRouter>,
    rootElement
  )
}

export default app