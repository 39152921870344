import React from 'react';
import '../assets/hero.css'; // Crea un archivo CSS para el componente

function Hero({ style, urlBackground, title, description }) {
    return (
        <div>
            {
                style == 'home' ?
                    <div className="hero-container">
                        <div className="hero-content">
                            <h3 className='display-4 fw-bold'>Soluciones Integrales en Instrumentos Industriales</h3>
                            <img className='logo-hero' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75" alt="Logotipo" />
                        </div>
                    </div>
                    :
                    <div className="hero-container-general" style={{ background: `url(${urlBackground})` }}>
                        <div className="hero-content p-4 d-flex flex-row justify-content-left">

                            <div id='mb' className="w-100">
                                <h3 className='display-3 fw-bold text-start'>{title}</h3>
                                <div className="my-4">
                                    <p className='fs-6 text-start text-white'>
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div id="pc" className="w-75">
                                <h3 className='display-3 fw-bold text-start'>{title}</h3>
                                <div className="my-4">
                                    <p className='fs-6 text-start text-white'>
                                        {description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Hero;
