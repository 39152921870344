import app from '../../../firebase';
import * as XLSX from 'xlsx/xlsx'
const db = app.firestore();

const timenow = () => {
    var now = new Date(),
        ampm = 'am',
        h = now.getHours(),
        m = now.getMinutes(),
        s = now.getSeconds();
    if (h >= 12) {
        if (h > 12) h -= 12;
        ampm = 'pm';
    }

    if (m < 10) m = '0' + m;
    if (s < 10) s = '0' + s;
    return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
}

export const MarcasController = {
    async all() {
        const querySnapshot = await db.collection('marcas')
            .orderBy('nombre', 'asc')
            .get();
        const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        return docs;
    },

    async save(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Lee el archivo y ejecuta el código de guardado
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Obtiene el primer hoja de cálculo del archivo Excel
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                // Convierte la hoja de cálculo a un objeto JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                // Transforma los datos en el formato deseado
                const transformedData = jsonData.map((item) => {
                    const transformedItem = {};


                    for (const key in item) {
                        if (Object.hasOwnProperty.call(item, key)) {
                            const trimmedKey = key.trim().replace(/\s+/g, '');
                            transformedItem[trimmedKey] = item[key];
                        }
                    }
                 

                    return transformedItem;
                });

                // Elimina los datos anteriores de la colección
                db.collection("marcas")
                    .get()
                    .then((snapshot) => {
                        const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
                        return Promise.all(deletePromises);
                    })
                    .then(() => {
                        // Guarda los nuevos datos en la colección
                        const addPromises = transformedData.map((item) => db.collection("marcas").add(item));
                        return Promise.all(addPromises);
                    })
                    .then(() => {
                        resolve('Datos guardados en la colección: ' + "marcas");
                    })
                    .catch((error) => {
                        reject('Error al guardar los datos: ' + error);
                    });
            };

            // Lee el contenido del archivo como una matriz de bytes
            reader.readAsArrayBuffer(file);
        });
    },
}