import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import app from '../../firebase';
const db = app.firestore();
export default function Login() {


    let navigate = useNavigate()

    const [emailUser, setEmailUser] = useState('')
    const [userPassword, setUserPassword] = useState('')

    const handleLogin = async () => {
        if (emailUser != "" && userPassword != "") {
            await app.auth().signInWithEmailAndPassword(emailUser, userPassword).then((response) => {
                sessionStorage.setItem('userId', response.user.uid);
                if (response.user.uid != "") {
                    navigate('/admin/backoffice');
                } else {
                    alert("Usuario y/o Contraseña incorrectos");
                }
            }).catch((error) => {
                if (error.code == "auth/too-many-requests") {
                    alert("Límite de intentos alcanzado, tu cuenta ha sido bloqueada temporalmente, para recuperar el acceso inmediatamente, debes solicitar una nueva, de lo contrario intenta más tarde");
                } else if (error.code == "auth/wrong-password") {
                    alert("Contraseña incorrecta");
                }
            })
        }
    }

    useEffect(() => {
        let adminIdAuth = sessionStorage.getItem('userId')
        if (adminIdAuth) {
            navigate('/admin/backoffice')
        }
    }, [])
    return (
        <div className='container d-flex flex-column justify-content-center align-items-center minHeight-100'>
            <div className='iconContainerSmall'></div>
            <div className='mt-4 shadow'>
                <div className='m-4'>
                    <h2 className="text-dark fw-bold fs-1">Inicio de sesión</h2>
                    <div className='mt-2'>
                        <div className='form-group'>
                            <label className='mt-3'>Usuario</label>
                            <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setEmailUser(e.target.value)} ></input>
                        </div>
                        <div className='form-group'>
                            <label className='mt-3'>Contraseña</label>
                            <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setUserPassword(e.target.value)}></input>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row flex-column'>
                    <button onClick={() => handleLogin()} className="btn btn-danger m-4"><li className='fa fa-sign-in'></li> &nbsp; Iniciar sesión</button>
                </div>
            </div>
        </div>
    )
}
