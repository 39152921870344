import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './styles.css'
import WaFloatButton from '../../components/WaFloatButton'
import Hero from '../../components/Hero'


export default function Contacto() {

    return (
        <body>
            <main>
                <Header activeLink="contacto"></Header>

                <WaFloatButton></WaFloatButton>

                <Hero style={"contacto"} title={"Contacto"} description={"Nosotros valoramos tu opinión y estamos deseosos de escucharte. Nos interesa conocer más sobre tu proyecto para ofrecerte soluciones de calidad mundial que se adapten perfectamente a tus necesidades. Estamos comprometidos en brindarte resultados excepcionales y superar tus expectativas en cada paso del camino."} urlBackground={"https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fbg-contacto-coil.png?alt=media&token=ed05be38-ca0b-40f0-a740-90e227a939cb"} />

                <div id="pc" className="my-6 p-3">
                    <div className="d-flex justify-content-center">
                        <h4 className='text-danger fw-bold fs-3 text-center'>Nuestros productos están respaldados por años de experiencia y dedicación en proporcionar calidad y satisfacción a nuestros clientes".</h4>
                    </div>
                </div>
                <div id="mb" className="my-3 p-3">
                    <div className="d-flex justify-content-center">
                        <h4 className='text-danger fw-bold fs-3 text-left'>Nuestros productos están respaldados por años de experiencia y dedicación en proporcionar calidad y satisfacción a nuestros clientes".</h4>
                    </div>
                </div>

                <div className="container-fluid my-4">
                    <div className="row row-cols-2">
                        <div className="col-12 col-md-6">
                            <div className="bg-dark shadow p-4 h-100" style={{ borderRadius: "20px" }}>
                                <div className="my-2">
                                    <span className='text-white fs-2 fw-bold'>HABLEMOS</span>
                                </div>
                                <div className="my-4">
                                    <p className='text-white fs-6'>
                                        Llena el formulario a continuación y uno de nuestros ejecutivos se pondrá en contacto contigo para asesórate de manera profesional en tu proyecto
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <div className='my-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>

                                        <span className='text-light fs-7'>
                                            coilinstrumentacion@gmail.com
                                        </span>
                                    </div>
                                    <div className='my-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>

                                        <a href='tel:729 806 0042' className='text-light fs-7'>
                                            729 806 0042
                                        </a>
                                    </div>
                                    <div className='my-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>

                                        <a href='tel:729 806 0042' className='text-light fs-7'>
                                            729 806 0042
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="my-4 text-center">
                                <span className='text-danger fw-bold fs-3'>FORMULARIO DE CONTACTO</span>
                            </div>

                            <div className="my-3 row row-cols-2">
                                <div className="col">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder='Nombre' />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder='Apellidos' />
                                    </div>
                                </div>
                            </div>

                            <div className="my-3 row row-cols-2">
                                <div className="col">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder='Email' />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <input type="number" className="form-control" placeholder='Teléfono' />
                                    </div>
                                </div>

                            </div>

                            <div className="my-3">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder='Empresa' />
                                </div>
                            </div>
                            <div className="my-3">
                                <div className="form-group">
                                    <textarea rows={5} className="form-control" placeholder='Descripción del proyecto' />
                                </div>
                            </div>
                            <div className="my-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        He leído y acepto el aviso de privacidad
                                    </label>
                                </div>
                            </div>
                            <div className="my-3">
                                <button className="btn btn-dark w-100 small shadow" style={{ borderRadius: "5px" }}> <li className='fa fa-paper-plane'></li> Enviar mensaje</button>
                            </div>
                        </div>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5017824320785!2d-99.14321772561719!3d19.43392098184572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f92b5b005b95%3A0x4da8ca5798c532b7!2sTorre%20Latinoamericana%2C%20Eje%20Central%20L%C3%A1zaro%20C%C3%A1rdenas%202%2C%20Centro%20Hist%C3%B3rico%20de%20la%20Cdad.%20de%20M%C3%A9xico%2C%20Centro%2C%20Cuauht%C3%A9moc%2C%2006000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1694715558913!5m2!1sen!2smx" className='my-4 map' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </body>
    )
}
