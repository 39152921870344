import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore';
import app from '../../firebase';
import ActionCardAdmin from '../../components/ActionCardAdmin/ActionCardAdmin';
import Clock from 'react-clock'
import 'react-clock/dist/Clock.css';

const db = app.firestore();

export default function Dashboard() {


    let navigate = useNavigate()
    const [value, setValue] = useState(new Date());
    const [fecha, setFecha] = useState('');
    const [totalEquipos, setTotalEquipos] = useState(0)
    const [totalCotizaciones, setTotalCotizaciones] = useState(0)
    const [totalMarcas, setTotalMarcas] = useState(0)

    async function contarRegistrosEnColeccion(coleccion) {
        const collectionRef = collection(db, coleccion);
        const querySnapshot = await getDocs(collectionRef);
        const numeroDeRegistros = querySnapshot.size;
        return numeroDeRegistros;
    }


    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        const interval = setInterval(() => setValue(new Date()), 1000);

        // Días de la semana en español
        const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

        // Obtener la fecha actual
        const fechaActual = new Date();

        // Obtener el día de la semana actual
        const diaDeLaSemana = diasDeLaSemana[fechaActual.getDay()];

        // Formatear la fecha como "Día de la semana, día de mes de año"
        const opcionesDeFormato = { day: 'numeric', month: 'long', year: 'numeric' };
        const fechaFormateada = fechaActual.toLocaleDateString('es-ES', opcionesDeFormato);

        // Combinar el día de la semana y la fecha formateada
        const fechaCompleta = `${diaDeLaSemana}, ${fechaFormateada}`;

        // Establecer la fecha completa en el estado
        setFecha(fechaCompleta);

        contarRegistrosEnColeccion("equipos").then((conteo=>{
            setTotalEquipos(conteo)
        }))
        contarRegistrosEnColeccion("cotizacionesEquipos").then((conteo=>{
            setTotalCotizaciones(conteo)
        }))
        contarRegistrosEnColeccion("marcas").then((conteo=>{
            setTotalMarcas(conteo)
        }))
        
        return () => {
            clearInterval(interval);
        };


    }, [])

    return (
        <div className="container-fluid bg-gray-50 container-dashboard">
            <div className="container-clock">
                <center>
                    <img className='my-4' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75" alt="" />
                    <Clock value={value} className={"bg-light m-clock my-2"} size={"18vw"} />
                    <span className='text-white fw-bold fs-4'>{fecha}</span>
                </center>
            </div>
            <div className="row row-cols-2">
                <div className="col-12 col-md-6">
                    <ActionCardAdmin title={"Equipos"} number={totalEquipos} link={"/admin/backoffice/equipos"} />
                </div>
                <div className="col-12 col-md-6">
                    <ActionCardAdmin title={"Cotizaciones"} number={totalCotizaciones} link={"/admin/backoffice/cotizaciones"} />
                </div>
                <div className="col-12 col-md-6">
                    <ActionCardAdmin title={"Marcas"} number={totalMarcas} link={"/admin/backoffice/marcas"} />
                </div>
            </div>
        </div>
    )
}
