import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Cotizaciones() {
    let navigate = useNavigate()



    const [state, setState] = useState({})
    const [urlSlideDestino, setUrlSlideDestino] = useState("")
    const [currentImage, setCurrentImage] = useState(null)
    const [imageTempSlidePreview, setImageTempSlidePreview] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [altSlide, setAltSlide] = useState("")



    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }


    // Upload slides
    const handleSliderImageChange = (e) => {
        setCurrentImage(e.target.files[0])
        setImageTempSlidePreview(window.URL.createObjectURL(e.target.files[0]))
    }


    function formatearCadena(cadena) {
        //Reemplazar espacios por guiones medios
        cadena = cadena.replace(/\s+/g, '-');
        //Eliminar acentos
        cadena = cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Convertir a minúsculas
        cadena = cadena.toLowerCase();
        return cadena;
    }


    const handleUploadImageLand = () => {

        console.log("first")
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();


        const uploadTask = firebase.storage().ref(`slides/${currentImage.name}`).put(currentImage);

        uploadTask.on("state_changed",
            (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        console.log("Upload is paused");
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        console.log("Upload is running");
                        break;

                }
            }, (error) => {
                console.log("No fue posible cargar la imagen, código de error: ", error.code)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    state["url"] = formatearCadena(state["url"])
                    state["fechaHora"] = timestamp
                    state["imageUrl"] = downloadUrl

                    db.collection("landingPages").add(state).then(() => {
                        alert("Landing creada exitosamente")
                        window.location.reload()
                        setIsUploading(false)
                    }).catch((error) => {
                        setIsUploading(false)
                        alert("No fue posible crear el landing, error", error)
                        window.location.reload()

                    })
                    // }) DEMETRIO ESTA LOQUITO JAJA <POMPIS></POMPIS> <CABEZON></CABEZON>
                })
            }
        )
    }

    useEffect(() => {
        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }
    }, [])
    return (
        <div>
            <NavbarBack></NavbarBack>

            <div className="p-4">
                <div className="my-4">
                    <span className='display-6'>Crear Landing Page</span>
                </div>

                <div className="my-1">
                    <div className="form-group">
                        <input className="form-control fs-12 my-2" type="text" onChange={(e) => handleTextChange("titulo", e.target.value)} placeholder='Título landing' />

                        <div class="input-group input-group-sm mb-3">
                            <span class="input-group-text" id="inputGroup-sizing-sm">prosesamed.com/</span>
                            <input className="form-control" type="text" onChange={(e) => handleTextChange("url", e.target.value)} placeholder='Url landing' />
                        </div>

                        <label htmlFor="imagenLanding" className='fs-12'>Seleccione imagen de landing</label>
                        <input type="file" id='imagenLanding' onChange={handleSliderImageChange} className='form-control fs-12-sm my-2' />
                        <input type="text" placeholder='Alt del slider' className='form-control fs-12 my-2' onChange={(e) => handleTextChange("altImage", e.target.value)} />
                    </div>
                    <div className="d-flex flex-row-reverse my-3">
                        <button className='m-2 btn btn-green fs-12 text-light' onClick={() => handleUploadImageLand()}>Crear landing&nbsp;
                            <li className='fa fa-save'></li>
                        </button>
                        <button className='m-2 btn btn-secondary fs-12 text-light' onClick={() => window.location.reload()}>Limpiar &nbsp;
                            <li className='fa fa-refresh'></li>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
