import React from 'react';
import '../assets/porqueelegirnos.css'; // Crea un archivo CSS para el componente

function PorQueElegirnos() {
    return (
        <section className="por-que-elegirnos h-50">
            <div className="container">
                <h2 className='text-white fw-bold'>¿Por qué elegirnos?</h2>
                <p id='description' className='text-white fs-6'>
                    En nuestro compromiso constante con la excelencia, ofrecemos a nuestros clientes una experiencia incomparable. Nuestro equipo altamente capacitado y dedicado se esfuerza por brindar productos/servicios de la más alta calidad. Nos enorgullece nuestra atención personalizada, la innovación constante y la satisfacción del cliente. Cuando nos eliges, eliges calidad, confiabilidad y un socio en el que puedes confiar
                </p>
                <button className="btn btn-light text-dark rounded fs-4"><li className='fa fa-arrow-right'/> Contáctanos ahora</button>
            </div>
        </section>
    );
}

export default PorQueElegirnos;
