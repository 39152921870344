import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Categorias() {
    let navigate = useNavigate()


    const [categorias, setCategorias] = useState([])


    // Modal states
    const [stateCategoria, setStateCategoria] = useState({})



    const handleCategoriaTextChange = (name, value) => {
        setStateCategoria({ ...stateCategoria, [name]: value })
    }

    // Functions

    const saveCategoria = () => {


        if (stateCategoria.nombre !== "" && stateCategoria.slug !== "") {
            stateCategoria["slug"] = formatearCadena(stateCategoria.slug)
            db.collection("categorias").add(stateCategoria).then(() => {
                alert("Categoría guardada")
                window.location.reload()
            })
        } else {
            alert("Debe completar todos los campos")
        }
    }

    
    function formatearCadena(cadena) {
        //Reemplazar espacios por guiones medios
        cadena = cadena.replace(/\s+/g, '-');
        //Eliminar acentos
        cadena = cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Convertir a minúsculas
        cadena = cadena.toLowerCase();
        return cadena;
    }


    const deleteCategoria = (id) => {

        if (window.confirm("¿Eliminar categoría?")) {

            db.collection("categorias").doc(id).delete().then(() => {
                alert("Categoría eliminada")
            }).catch((error) => {
                alert("Error al eliminar la categoría, código de error: ", error.message)
            })
        }
    }


    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener categorías
        db.collection("categorias").orderBy("nombre", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setCategorias(docs)
                console.log(categorias)
            })
    }, [])
    return (
        <div>
            <NavbarBack></NavbarBack>

            <div className="p-4">
                <div className="my-4">
                    <span className='display-6'>Categorías</span>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <button className='btn btn-green fs-12 text-light' data-bs-toggle="modal" data-bs-target="#modalNuevaCategoria">Nueva &nbsp; <li className='fa fa-plus'></li>
                    </button>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Nombre</th>
                            <th className='text-dark fs-12'>Slug</th>
                            <th className='text-dark fs-12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            categorias.map((categoria) => (
                                <tr>
                                    <td>
                                        <span className="fs-12 text-dark">{categoria.nombre}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{categoria.slug}</span>
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-danger fs-12" onClick={() => deleteCategoria(categoria.id)}>
                                            <li className='fa fa-trash'></li>
                                        </button>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Modal nueva categoría */}
            <div className="modal fade" id="modalNuevaCategoria" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Nueva categoría</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Nombre de la categoría*</label>
                                <input type="text" onChange={(e) => handleCategoriaTextChange("nombre", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label>Slug*</label>
                                <input type="text" onChange={(e) => handleCategoriaTextChange("slug", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="my-2">
                                <span className='text-danger fs-12'>* Datos obligatorios</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary fs-12" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-outline-dark fs-12" data-bs-dismiss="modal" onClick={saveCategoria}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal nueva categoría */}
        </div>
    )
}
