import React from 'react'

export default function Footer() {
  return (
    <div className="bg-dark container-fluid p-4">
      <div className="row">
        <div className="col my-4 line-right">
          <div className='my-4 d-flex justify-content-center'>
            <div className='logo_container_footer_color'></div>
          </div>
        </div>
        <div className="col line-right">
          <div className="width-spacer"></div>
          <div className='my-2'>
            <span className='text-light fw-bold fs-4'>Legal</span>
          </div>
          <div className='my-4'>
            <ul>
              <li className='text-light fs-6'>Aviso de privacidad</li>
              <li className='text-light fs-6'>Términos y condiciones</li>
            </ul>
            <span className='text-light fs-7'>El contenido de este sitio está protegido por leyes de Propiedad Industrial. Se prohíbe la reproducción sin autorización escrita del titular de los derechos.</span>
          </div>
        </div>
        <div className="col line-right">
          <div className="width-spacer"></div>
          <div className='my-2'>
            <span className='text-light fw-bold fs-4'>Información de contacto</span>
          </div>
          <div className='my-4'>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
              </svg>

              <span className='text-light fs-7'>
                coilinstrumentacion@gmail.com
              </span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
              </svg>

              <a href='tel:729 806 0042' className='text-light fs-7'>
                729 806 0042
              </a>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
              </svg>

              <a href='tel:729 806 0042' className='text-light fs-7'>
                729 806 0042
              </a>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="icon-footer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <span className='text-light fs-7'>
                Horarios de atención:
              </span>

              <span className='text-light fs-7 mx-2 text-center'>
                Lunes a viernes de 9:00am 6:00pm
              </span>
            </div>
          </div>
        </div>

        <div className="text-center my-2">
          <a href="https://desarrollalab.com" className='text-light small'>Desarrollado por Desarrollalab.com</a>
        </div>
      </div>
    </div>
  )
}
