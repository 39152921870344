import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WaFloatButton from '../components/WaFloatButton'
export default function GraciasCotizacion() {

    const params = useParams()
    const navigate = useNavigate()

    // Functions
    const getFolio = () => {
        return params.idCotizacion.slice(0, 7)
    }


    useEffect(() => {
        window.scrollTo(0, 0)

        if (params.idCotizacion == "") {
            navigate("/inicio")
        }
    }, [])
    return (
        <main>
            <Header></Header>
            <WaFloatButton></WaFloatButton>

            <div className="container-fluid bg-light-gray d-flex justify-content-center flex-column align-items-center">
                <div className="check-confirmation my-4"></div>
                <div className="my-4">
                    <span className="display-6 fw-bold my-2">¡Solicitud recibida!</span>
                    <p className='my-2'>En breve un ejecutivo se contactará contigo para ayudarte a concretar tu compra</p>
                    <p className='my-2'>Folio de cotización: <strong>{getFolio()}</strong></p>
                    <span className="fs-6 fw-bold">¡Gracias por confiar en Prosesamed</span>
                </div>
                <div className="my-4">
                    <Link to="/" className='btn btn-dark'>Ir al inicio ➤➤ </Link>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}
