import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import app from '../firebase';
import WaFloatButton from '../components/WaFloatButton';
import SEO from '@americanexpress/react-seo';
const db = app.firestore();

export default function EquipoVisualizer() {

    const params = useParams();
    const navigate = useNavigate()

    // States
    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [equipoData, setEquipoData] = useState([])
    const [currenImageView, setCurrentImageView] = useState("");
    const [marcas, setMarcas] = useState([])

    // Handlers

    const handleChageText = (name, value) => {
        setState({ ...state, [name]: value })
    }


    // Functions

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }


    const registrarCotizacion = () => {


        state["equipoId"] = equipoData.id
        state["fechaHora"] = timenow()

        db.collection("cotizacionesEquipos").add(state).then((docRef) => {
            window.location.href = `https://api.whatsapp.com/send?phone=+525549057667&text=Saludos, solicito cotizar el equipo, ${equipoData.nombre}, que tiene el SKU: ${equipoData.codigo}, mi nombre es: ${state.nombreCliente}, correo: ${state.correoCliente},teléfono: ${state.telefonoCliente}`
        }).catch((error) => {
            alert("No se pudo enviar la cotización intente nuevamente, código de error: ", error.message)
        })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        db.collection("equipos").where("identificadorEquipo", "==", params.linkId).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setEquipoData({ ...doc.data(), id: doc.id })
                setCurrentImageView(doc.data().images[0])
                setIsLoading(false);
            })
        })

        //   Obtener marcas
        db.collection("marcas").orderBy("orden", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setMarcas(docs)
            })
    }, [])

    return (
        <main>
            <Header activeLink="equipos"></Header>
            <WaFloatButton></WaFloatButton>
            <meta name="robots" content="index, follow" />

            <div className="container">

                {
                    isLoading == true ?
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-dark" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <>

                            {/* SEO PLUGIN*/}
                            <SEO
                                title={`${equipoData.nombre} | Coil Instrumentación`}
                                description={`${equipoData.descripcionCompleta} Marcas: CHARTS, BARTON , OGME , TECHCAL BOURDON (ES) DE PRESION, SENSORES DE TEMPERATURA , PLATO DIFERENCIAL ,  RELOJES PARA REGISTRADOR`}
                                robots={['index', 'follow']}
                                // keywords={['foo', 'bar']}
                                siteUrl={`https://coilinstrumentacion/${params.category}/${params.linkId}`}
                                image={
                                    {
                                        src: equipoData?.images[0],
                                        alt: equipoData?.nombre,
                                    }
                                }
                                openGraph={{
                                    title: 'Facebook Coil Instrumentación SA de CV',
                                    description: 'Coil Instrumentación SA de CV',
                                    image: {
                                        src: equipoData.images[0],
                                        alt: equipoData.nombre,
                                    },
                                    siteName: `${equipoData.nombre}`
                                }}
                            />

                            {/* ENDS SEO PLUGIN */}
                            <div id="mb">
                                <div className="my-4 bg-light-gray p-4">
                                    <Link className='text-dark fs-6' to={"/equipos"}>Equipos / </Link>
                                    <span className='text-dark fs-6'>{equipoData.nombre}</span>
                                </div>
                            </div>
                            <div className="row row-cols-2 my-4">

                                <div className="col-12 col-md-6">
                                    <div className='shadow my-4 equipo-main-image'
                                        style={{
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            justifyContent: 'center',
                                            height: "400px",
                                            backgroundImage:
                                                `url(${(currenImageView)})`
                                        }}
                                    >

                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex align-content-center flex-column">
                                    <div className="my-4">
                                        <span className='text-dark display-5 fw-bold'>{equipoData.nombre}</span>
                                    </div>
                                    <div className="container-fluid p-2 bg-light-gray">
                                        <div className="row row-cols-auto justify-content-center">

                                            {
                                                equipoData.images.map((url, i) => (
                                                    <div className="col">

                                                        <div
                                                            key={i}
                                                            onClick={() => setCurrentImageView(url)}
                                                            className='preview-equipo'
                                                            style={{
                                                                backgroundSize: "contain",
                                                                backgroundPosition: "center",
                                                                backgroundRepeat: "no-repeat",
                                                                justifyContent: 'center',
                                                                backgroundImage:
                                                                    `url(${(url)})`
                                                            }}></div>
                                                    </div>
                                                ))
                                            }

                                            <div className="my-4 w-100">
                                                <button className='btn btn-dark fs-5 w-100'
                                                    data-bs-toggle="modal" data-bs-target="#modalCotizar">
                                                    Cotizar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container my-4">

                                <div className="my-4">
                                    <span className="fs-3 fw-bold">Marcas que manejamos</span>
                                </div>
                                <div className="row row-cols-3">
                                    {
                                        marcas.slice(0, 6).map((marca) => (
                                            <div className="col-12 col-md-4">
                                                <img src={marca.imagen} />
                                            </div>
                                        ))
                                    }
                                </div>
                                <Link to={"/marcas"} className="btn btn-outline-white small shadow my-4">Ver todas las marcas</Link>

                                <div className="row row-cols-1">
                                    <div className='my-4'>
                                        <div className="my-4">
                                            <span className="fs-3 fw-bold">Para qué sirve el: {equipoData.nombre}</span>
                                        </div>
                                        <div className="my-2 text-start p-3 bg-gray-50">
                                            <p className='fs-6 text-start'>{equipoData.descripcion}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </>
                }

            </div>
            <Footer></Footer>


            {/* Modal cotizar */}


            <div className="modal fade-div" id="modalCotizar" tabindex="-1" aria-labelledby="labelModalCotizar" aria-hidden="true">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold" id="exampleModalLabel">Cotizar equipo {equipoData.nombre}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div className="container">
                                <div className="row row-cols-2">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group my-2">
                                            <label>Nombre completo</label>
                                            <input onChange={(e) => handleChageText("nombreCliente", e.target.value)} type="text" id="text" className="form-control" />
                                        </div>
                                        <div className="form-group my-2">
                                            <label>Correo electrónico</label>
                                            <input onChange={(e) => handleChageText("correoCliente", e.target.value)} type="text" id="text" className="form-control" />
                                        </div>
                                        <div className="form-group my-2">
                                            <label>Teléfono</label>
                                            <input onChange={(e) => handleChageText("telefonoCliente", e.target.value)} type="text" id="text" className="form-control" />
                                        </div>
                                        <div id="pc">
                                            <a href='/privacidad' className='text-dark fs-10'>Al cotizar aceptas nuestro aviso de privacidad</a>
                                        </div>
                                    </div>

                                    {


                                        equipoData.length !== 0 ?



                                            <div className="col-12 col-md-6">
                                                <div
                                                    className='preview-equipo-modal'
                                                    style={{
                                                        backgroundSize: "contain",
                                                        backgroundPosition: "center",
                                                        backgroundRepeat: "no-repeat",
                                                        justifyContent: 'center',
                                                        backgroundImage:
                                                            `url(${(equipoData.images[0])})`
                                                    }}>
                                                </div>
                                            </div>


                                            :
                                            <div></div>
                                    }



                                </div>

                                <div id="mb" className='bg-light-gray p-1 my-2'>
                                    <a href='/privacidad' className='text-dark fs-10'>Al cotizar aceptas nuestro aviso de privacidad</a>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" onClick={() => registrarCotizacion()} className="btn btn-dark" data-bs-dismiss="modal">Solicitar cotización</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal cotizar */}
        </main>
    )
}
