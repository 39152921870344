import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Listado() {
    let navigate = useNavigate()



    const [landings, setLandings] = useState([])

    function convertirFirebaseTimestamp(timestamp) {
        const date = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir los segundos a milisegundos
        const dia = date.getDate();
        const mes = date.getMonth() + 1; // Los meses en JavaScript comienzan en 0, así que sumamos 1
        const anio = date.getFullYear();

        return `${dia}/${mes}/${anio}`;
    }


    const deleteLandingById = (id) => {
        db.collection("landingPages").doc(id).delete().then(() => {
            alert("Landing eliminado exitosamente")
        }).catch((e) => {
            alert("Hubo un error en el sistema: ", e.message)
        })
    }

    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener cotizaciones
        db.collection("landingPages").orderBy("fechaHora", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setLandings(docs)
            })
    }, [])
    return (
        <div>
            <NavbarBack></NavbarBack>

            <div className="p-4">
                <div className="my-4">
                    <span className='display-6'>Listado de Landing Pages</span>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <Link to={"/admin/backoffice/landing/create"} className='btn btn-green fs-12 text-light'>Nueva &nbsp; <li className='fa fa-plus'></li></Link>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Publicado el</th>
                            <th className='text-dark fs-12'>Url destino</th>
                            <th className='text-dark fs-12'>Imagen landing</th>
                            <th className='text-dark fs-12'>Alt imagen</th>
                            <th className='text-dark fs-12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            landings.map((land) => (
                                <tr>
                                    <td>
                                        <span className="fs-12 text-dark">{convertirFirebaseTimestamp(land.fechaHora)}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.url}</span>
                                    </td>
                                    <td>
                                        <a href={land.imageUrl} target={"_blank"}>
                                            <img src={land.imageUrl} width="50" height={"auto"} />
                                        </a>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.titulo}</span>
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-danger fs-12" onClick={() => deleteLandingById(land.id)}>
                                            <li className='fa fa-trash'></li>
                                        </button>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
