import React from 'react';
import Slider from 'react-slick';
import Logo from './Logo';

const LogoCarousel = ({ logos }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        focusOnSelect: true,
        pauseOnHover:false,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    return (
        <Slider {...settings}>

            {
                logos.map((logo, index) => (
                    <Logo key={index} logo={logo} />
                ))
            }
        </Slider>
    );
};

export default LogoCarousel;
