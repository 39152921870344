import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CardEquipo from '../components/CardEquipo'

import FilterResults from 'react-filter-search'

// Firebase
import firebase from 'firebase/compat/app';
import app from '../firebase';
import WaFloatButton from '../components/WaFloatButton'
import TarjetaProducto from '../components/productosDestacados/TarjetaProducto'
const db = app.firestore();

export default function Equipos() {

  const [isLoading, setIsloading] = useState(true)
  const [equipos, setEquipos] = useState([])
  const [searchParam, setSearchParam] = useState("")

  // States for filters
  const [categorias, setCategorias] = useState([])
  const [currentSlug, setCurrentSlug] = useState("")
  const [currentPositionRange, setCurrentPositionRange] = useState("")
  const [currentPrecio, setCurrentPrecio] = useState("")
  const [maxRange, setMaxRange] = useState(0)

  const [precios, setPrecios] = useState([])




  const handleRemoveSlug = () => {
    setCurrentSlug("")
    getAllEquipos()
  }



  // Functions
  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }


  const getAllEquipos = () => {
    db.collection("equipos")
      .orderBy("nombre", "asc")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        const auxPrecios = [];
        const docsSinOrden = [];

        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id };
          if (typeof data.orden === 'number') {
            docs.push(data); // Registros con campo "orden" numérico
            auxPrecios.push(doc.data().precio);
          } else {
            docsSinOrden.push(data); // Registros sin campo "orden" o no numérico
          }
        });

        // Ordenar registros con campo "orden" de menor a mayor
        docs.sort((a, b) => (a.orden || 0) - (b.orden || 0));

        // Combinar registros ordenados y registros sin orden
        const equiposOrdenados = [...docs, ...docsSinOrden];

        setEquipos(equiposOrdenados);
        setMaxRange(equiposOrdenados.length);
        setPrecios(auxPrecios);
        setIsloading(false);
      });
  }


  useEffect(() => {

    // For get equipos 
    getAllEquipos()

    window.scrollTo(0, 0)

  }, [])


  return (

    <main>
      <Header activeLink="productos"></Header>
      <WaFloatButton></WaFloatButton>
      <div className="container-fluid">
        <div className="my-4 text-left">
          <h1 className='text-dark fw-bold display-6'>Productos</h1>
        </div>
        <div className="my-4 border">
          <div className="from-group shadow-lite">
            <input type="text" className='nb-input form-control border-0 p-3' onChange={(e) => setSearchParam(e.target.value)} placeholder="Buscar producto" name="Buscar productos" />
          </div>
        </div>


        <div className="container-fluid">
          {

            isLoading == true ?
              <div className="d-flex justify-content-center">
                <div class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              :
              <div className="container-fluid">
                {
                  equipos.length > 0 ?


                    <div className="row row-cols-1">
                      <div className="col-12 col-md-12">
                        <div className="container fade-div">

                          {
                            currentSlug !== "" ?

                              <div className="my-2 w-100">
                                <button className='btn btn-outline-primary fs-12' onClick={() => handleRemoveSlug()}>{capitalize(currentSlug)}&nbsp;&nbsp;<strong>X</strong></button>
                              </div>
                              :
                              <br />
                          }
                          <div className="row row-cols-3 justify-content-center">

                            <FilterResults
                              value={searchParam}
                              data={equipos}
                              renderResults={results => (

                                results.length !== 0 ?

                                  results.map((equipo, index) => (
                                    <div className="col-12 col-md-4">

                                      <TarjetaProducto

                                        titulo={equipo.nombre} descripcion={equipo.descripcion} imagenUrl={equipo.images[0]} enlace={`https://coilinstrumentacion.com/equipos/${equipo.identificadorEquipo}`}
                                      />
                                    </div>
                                  ))
                                  :
                                  <span>No hemos encontrado el equipo, prueba con parametros distintos</span>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    :
                    <div className="container my-6">


                      <div className="row">



                        <div className="col-9">
                          <div class="alert alert-secondary" role="alert">
                            No hay productos en este momento, prueba buscar más tarde
                          </div>
                        </div>
                      </div>

                    </div>
                }
              </div>
          }
        </div>
      </div>
      <Footer></Footer>
    </main>
  )
}
