import React from 'react'

export default function WaFloatButton() {
    return (
        <a href="https://wa.link/gx65rk" class="btn float shadow"
            target="_blank">
            <div className='d-flex align-items-center justify-content-center w-100'>
                <i className="fa fa-whatsapp wp-button"></i>
                <span className='text-btn'>Escríbenos ahora</span>
            </div>
        </a>

    )
}
