import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import firebase from 'firebase/compat/app';
import NavbarBack from '../../../components/NavbarBack'
import app from '../../../firebase';
import Swal from "sweetalert2";
import { EquiposController } from './EquiposController';
import FilterResults from 'react-filter-search'

// Firebase

export default function Equipos() {
    const db = app.firestore();
    let navigate = useNavigate()

    // States
    const [categorias, setCategorias] = useState([])
    const [currentProducto, setCurrentProducto] = useState({})
    const [hasRemoved, setHasRemoved] = useState(false)
    const [equipos, setEquipos] = useState([])
    const [numberOfItems, setNumberOfItems] = useState(10)
    const [searchParam, setSearchParam] = useState("")

    // Files 
    const [isUploading, setIsUploading] = useState(false)
    const [images, setImages] = useState([]);
    const [currentImagesProduct, setCurrentImagesProduct] = useState([])
    const [urls, setUrls] = useState([]);
    const [progress, setProgress] = useState(0);

    // Modal states
    const [stateProduct, setStateProduct] = useState({})
    const [stateUpdateProduct, setStateUpdateProduct] = useState({})
    const [stateCaracteristica, setStateCaracteristica] = useState({})


    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false)
    // Maneja el evento de cambio del input de archivo
    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };

    const handleImagesChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const newImage = e.target.files[i];
            newImage["id"] = Math.random();
            setImages((prevState) => [...prevState, newImage]);
        }
    };

    const handleUpload = () => {


        if (images.length !== 0) {
            const promises = [];
            images.map((image) => {
                const uploadTask = firebase.storage().ref(`images/${image.name}`).put(image);
                promises.push(uploadTask);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        setIsUploading(true)
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgress(progress);
                    },
                    (error) => {
                        console.log(error);
                    },
                    async () => {
                        await firebase.storage()
                            .ref("images")
                            .child(image.name)
                            .getDownloadURL()
                            .then((urls) => {
                                setUrls((prevState) => [...prevState, urls]);
                            });
                    }
                );
            });

            Promise.all(promises)
                .then(() => {
                    setIsUploading(false)
                    alert("Imágenes cargadas")
                })
                .catch((err) => console.log(err));
        } else {
            alert("No hay imágenes cargadas")
        }

    };


    const handleProductoUpdateChange = (name, value) => {
        setStateUpdateProduct({ ...stateUpdateProduct, [name]: value })
    }

    const handleProductoTextChange = (name, value) => {
        setStateProduct({ ...stateProduct, [name]: value })
    }

    const handleSetCurrentProducto = (producto) => {
        setCurrentImagesProduct(producto.images)
        setCurrentProducto(producto)
    }


    // Functions

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    function formatearCadena(cadena) {
        //Reemplazar espacios por guiones medios
        cadena = cadena.replace(/\s+/g, '-');
        //Eliminar acentos
        cadena = cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Convertir a minúsculas
        cadena = cadena.toLowerCase();
        return cadena;
    }




    const saveProducto = () => {

        if (stateProduct.nombre != null && stateProduct.identificadorEquipo != null) {

            stateProduct["identificadorEquipo"] = formatearCadena(stateProduct.identificadorEquipo)

            stateProduct["fechaHora"] = timenow()
            stateProduct["images"] = urls
            alert("Guardando producto...")
            db.collection("equipos").add(stateProduct).then(() => {
                alert("Producto guardado")
                window.location.reload()
            })
        } {
            alert("Debe completar todos los campos obligatorios")
        }



    }

    const updateProducto = () => {
        if (urls.length !== 0) {
            const urlsTemp = currentImagesProduct
            urls.map((image) => {
                urlsTemp.push(image)
            })
            db.collection('equipos').doc(currentProducto.id).update({ images: urlsTemp })
        }
        if (hasRemoved == true) {
            const urlsTemp = currentImagesProduct
            urls.map((image) => {
                urlsTemp.push(image)
            })
            db.collection('equipos').doc(currentProducto.id).update({ images: urlsTemp })
        }

        db.collection("equipos").doc(currentProducto.id).set(stateUpdateProduct, { merge: true }).
            then(() => {
                alert("Producto actualizado")
                window.location.reload()
            }).catch((error) => {
                alert("No fue posible actualizar el producto, error", error.message)
                window.location.reload()
            })


    }


    const removeUrl = (link) => {
        let filteredArray = urls.filter((e) => {
            return e !== link
        })
        setUrls(filteredArray)
        if (filteredArray.length == 0) {
            setUrls([])
            setImages([])
            document.getElementById("inputImages").value = ""
        }
    }


    const removeUrlCurrentProduct = (link) => {
        let filteredArray = currentImagesProduct.filter((e) => {
            return e !== link
        })
        setHasRemoved(true)
        setCurrentImagesProduct(filteredArray)
    }


    const deleteProductById = (id) => {
        if (window.confirm("¿Eliminar producto?")) {
            db.collection("equipos").doc(id).delete().then(() => {
                alert("Producto eliminado")
            }).catch((error) => {
                alert("Error al eliminar el producto, código de error: ", error.message)
            })
        }
    }

    const handleSetOutstanding = (id, destacadoActual) => {
        const confirmMessage = destacadoActual
            ? "¿Desmarcar como destacado?"
            : "¿Marcar como destacado?";

        if (window.confirm(confirmMessage)) {
            const nuevoEstadoDestacado = !destacadoActual;

            db.collection("equipos")
                .doc(id)
                .update({
                    destacado: nuevoEstadoDestacado,
                })
                .then(() => {
                    if (nuevoEstadoDestacado) {
                        alert("Producto marcado como destacado");
                    } else {
                        alert("Producto desmarcado como destacado");
                    }
                })
                .catch((error) => {
                    alert("Error al actualizar el estado del producto: " + error.message);
                });
        }
    };


    const handleUploadEquipos = () => {
        setLoading(true)
        EquiposController.save(file).then(() => {
            Swal.fire({
                title: 'Equipos actualizados',
                text: 'Se han actualizado los equipos correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#1ab394',

            }).then(() => {
                setLoading(false)
            })
        }).catch((error) => {
            setLoading(false)
            Swal.fire({
                title: 'Hubo un error en el sistema',
                text: 'Código de error: ' + error,
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#1ab394',

            })
        })
    };

    const returnOptionsRange = () => {
        const registrosPorPagina = 10;
        const totalRegistros = equipos.length;
        const totalOpciones = Math.ceil(totalRegistros / registrosPorPagina);
        const opciones = [];

        for (let i = 0; i < totalOpciones; i++) {
            const valor = (i + 1) * registrosPorPagina;
            opciones.push(<option key={i} value={valor}>{valor}</option>);
        }

        return opciones;
    };


    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener productos
        db.collection("equipos").orderBy("fechaHora", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setEquipos(docs)
            })

        db.collection("categorias").orderBy("nombre", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setCategorias(docs)
                console.log(categorias)
            })
    }, [])
    return (
        <div className='container-dashboard'>
            <div className="p-4 bg-white shadow" style={{ borderRadius: "20px" }}>
                <div className="my-4">
                    <span className='display-6 fw-bold'>Equipos</span>
                </div>
                <div className="my-4">
                    <Link className='text-dark btn-outline' to={"/admin/backoffice"}>Volver</Link>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <button className='btn btn-green fs-12 text-light' data-bs-toggle="modal" data-bs-target="#modalNuevoProducto">Nuevo &nbsp; <li className='fa fa-plus'></li>
                    </button>
                </div>

                <div className='d-flex flex-row-reverse'>
                    <div className="form-group my-2 p-2 border rounded">
                        <label className='small fw-bold'>Cargar por excel</label>
                        <input type="file" id="file" onChange={handleFileChange} className="form-control" />
                    </div>
                </div>
                {
                    file ?
                        !loading ?
                            <div className='d-flex flex-row-reverse'>
                                <button onClick={handleUploadEquipos} className='btn btn-green fs-12 text-light'>Cargar productos &nbsp; <li className='fa fa-upload'></li>
                                </button>
                            </div>
                            :
                            <div className='d-flex flex-row-reverse'>
                                <button className='btn btn-green disabled fs-12 text-light'>
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    Cargando productos
                                </button>
                            </div>

                        :
                        null
                }


                <div className="w-20">
                    <label className='xsmall'>Mostrando {numberOfItems} de {equipos.length} registros</label>
                    <select select class="form-select form-select-sm" onChange={(e) => setNumberOfItems(e.target.value)}>
                        {returnOptionsRange()}
                    </select>
                </div>

                <div className="my-4 border">
                    <div className="from-group shadow-lite">
                        <input type="text" className='nb-input form-control border-0 p-3' onChange={(e) => setSearchParam(e.target.value)} placeholder="Buscar producto" name="Buscar productos" />
                    </div>
                </div>

                <div className="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre del equipo</th>
                                <th scope="col">Orden</th>
                                <th scope="col">Detalles</th>
                                <th scope="col">Destacar</th>
                                <th scope="col">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <FilterResults
                                value={searchParam}
                                data={equipos.slice(0, numberOfItems)}
                                renderResults={results => (

                                    results.length !== 0 ?

                                        results.map((equipo, index) => (
                                            <tr key={equipo.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{equipo.nombre}</td>
                                                <td>{equipo.orden}</td>
                                                <td>
                                                    <button className="btn btn-green fs-12 text-light" onClick={() => handleSetCurrentProducto(equipo)} data-bs-toggle="modal" data-bs-target="#modalVerProducto">Ver detalles</button>
                                                </td>
                                                <td className='d-flex justify-content-center'>
                                                    <button
                                                        className={`btn ${equipo.destacado ? 'btn-warning' : 'btn-light border text-dark'} fs-12 text-light`}
                                                        onClick={() => handleSetOutstanding(equipo.id, equipo.destacado)}>
                                                        <i className={`fa ${equipo.destacado ? 'fa-star' : 'fa-star-o'}`}></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button className="btn btn-outline-danger fs-12" onClick={() => deleteProductById(equipo.id)}><i className="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <span>No hemos encontrado el equipo, prueba con parametros distintos</span>
                                )}
                            />
                        </tbody>
                    </table>
                </div>
            </div>



            {/* Modal nuevo producto */}
            <div className="modal fade" id="modalNuevoProducto" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Nuevo producto</h1>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div>
                                <span className='text-danger fs-12'>
                                    * Campos obligatorios
                                </span>
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Nombre del producto*</label>
                                <input type="text" onChange={(e) => handleProductoTextChange("nombre", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Nombre del producto*</label>
                                <input type="number" onChange={(e) => handleProductoTextChange("orden", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Link del equipo*</label>
                                <input type="text" onChange={(e) => handleProductoTextChange("identificadorEquipo", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Código o SKU*</label>
                                <input type="text" onChange={(e) => handleProductoTextChange("codigo", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>

                            <div className="form-group">
                                <label className='fs-12'>Imágenes del producto</label>
                                <input onChange={handleImagesChange} id="inputImages" type="file" multiple={true} className="form-control fs-12 form-control fs-12-sm my-2 fs-12" />
                            </div>
                            {
                                isUploading == true ?
                                    <progress value={progress} max="100" />
                                    :
                                    <div></div>
                            }
                            <div className="table-responsive border my-4 rounded">
                                <table className="table">
                                    <tbody>
                                        <tr>

                                            {


                                                urls.length !== 0 ?
                                                    urls.map((url, i) => (
                                                        <td className="col">
                                                            <a target={"_blank"} href={url}>
                                                                <img
                                                                    className="preview-image-project"
                                                                    key={i}
                                                                    src={url || "http://via.placeholder.com/300"}
                                                                    alt="firebase-image"
                                                                />
                                                            </a>
                                                            <div className="w-50">
                                                                <button className='btn btn-outline-danger fs-12 my-2' onClick={() => removeUrl(url)}>Quitar</button>
                                                            </div>
                                                        </td>

                                                    ))

                                                    :
                                                    <span className='fs-12'>No hay imágenes cargadas</span>
                                            }


                                        </tr>
                                    </tbody>
                                </table>
                            </div>



                            <button className="btn btn-outline-dark fs-12" onClick={handleUpload}>Cargar</button>

                            <div className="my-2 border">
                                <div className="m-1">
                                    <label className='fs-12'>Descripción</label>
                                    <input type="text" onChange={(e) => handleProductoTextChange("descripcion", e.target.value)} className="form-control fs-12" />
                                </div>
                                <div className="m-1">
                                    <label className='fs-12'>Para qué sirve el producto</label>
                                    <textarea rows={3} type="text" onChange={(e) => handleProductoTextChange("descripcionCompleta", e.target.value)} className="form-control fs-12" />
                                </div>
                            </div>

                        </div>



                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary fs-12" data-bs-dismiss="modal" onClick={() => window.location.reload()}>Cancelar</button>
                            <button type="button" className="btn btn-outline-dark fs-12" onClick={saveProducto}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal nuevo producto */}

            {/* Modal ver producto */}
            <div className="modal fade" id="modalVerProducto" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLambel">Detalles del producto</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <span className='text-danger fs-12'>
                                    * Campos obligatorios
                                </span>
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Nombre del producto*</label>
                                <input defaultValue={currentProducto.nombre} type="text" onChange={(e) => handleProductoUpdateChange("nombre", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>
                            <div className="form-group">
                                <label className='fs-12'>Identificador de equipo*</label>
                                <input defaultValue={currentProducto.identificadorEquipo} type="text" onChange={(e) => handleProductoUpdateChange("identificadorEquipo", e.target.value)} className="form-control fs-12 form-control fs-12-sm" />
                            </div>

                            <div className="form-group">
                                <label className='fs-12'>Imágenes del producto</label>

                                {
                                    currentImagesProduct && currentImagesProduct.length !== 0 ? (
                                        <div className="bg-light-blue">
                                            <div className='border p-2'>
                                                <span className='fs-12 fw-bold'>Imágenes actuales</span>
                                            </div>
                                            <div className="table-responsive border my-4 rounded">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            {currentImagesProduct.map((url, i) => (
                                                                <td className="col" key={i}>
                                                                    <a target={"_blank"} href={url}>
                                                                        <img
                                                                            className="preview-image-project"
                                                                            src={url || "http://via.placeholder.com/300"}
                                                                            alt="firebase-image"
                                                                        />
                                                                    </a>
                                                                    <div className="w-50">
                                                                        <button className='btn btn-outline-danger fs-12 my-2' onClick={() => removeUrlCurrentProduct(url)}>Quitar</button>
                                                                    </div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }




                                <input onChange={handleImagesChange} id="inputImages" type="file" multiple={true} className="form-control fs-12 form-control fs-12-sm my-2 fs-12" />
                            </div>
                            {
                                isUploading == true ?
                                    <progress value={progress} max="100" />
                                    :
                                    <div></div>
                            }
                            <div className="table-responsive border my-4 rounded">
                                <table className="table">
                                    <tbody>
                                        <tr>

                                            {


                                                urls.length !== 0 ?
                                                    urls.map((url, i) => (
                                                        <td className="col">
                                                            <a target={"_blank"} href={url}>
                                                                <img
                                                                    className="preview-image-project"
                                                                    key={i}
                                                                    src={url || "http://via.placeholder.com/300"}
                                                                    alt="firebase-image"
                                                                />
                                                            </a>
                                                            <div className="w-50">
                                                                <button className='btn btn-outline-danger fs-12 my-2' onClick={() => removeUrl(url)}>Quitar</button>
                                                            </div>
                                                        </td>

                                                    ))

                                                    :
                                                    <span className='fs-12'>No hay imágenes cargadas</span>
                                            }


                                        </tr>
                                    </tbody>
                                </table>
                            </div>



                            <button className="btn btn-outline-dark fs-12" onClick={handleUpload}>Cargar</button>


                            <div className="my-2 border">
                                <div className="m-1">
                                    <label className='fs-12'>Para qué sirve el producto</label>
                                    <textarea defaultValue={currentProducto.descripcion} rows={3} type="text" onChange={(e) => handleProductoUpdateChange("descripcionCompleta", e.target.value)} className="form-control fs-12" />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary fs-12" data-bs-dismiss="modal" onClick={() => window.location.reload()}>Cancelar</button>
                            <button type="button" className="btn btn-outline-dark fs-12" onClick={updateProducto}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin Modal ver producto */}
        </div>
    )
}
