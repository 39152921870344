import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import NavbarBack from '../../../components/NavbarBack'
// Firebase
import firebase from 'firebase/compat/app';
import app from '../../../firebase';
const db = app.firestore();

export default function Solicitudes() {
    let navigate = useNavigate()



    const [landings, setLandings] = useState([])

    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('userId')
        if (!adminIdAuth) {
            navigate('/admin/login')
        }

        //   Obtener cotizaciones
        db.collection("solicitudesLandingPages").orderBy("fechaHora", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setLandings(docs)
            })
    }, [])
    return (
        <div>
            <NavbarBack></NavbarBack>

            <div className="p-4">
                <div className="my-4">
                    <span className='display-6'>Solicitudes Landing Page</span>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th className='text-dark fs-12'>Fecha y hora</th>
                            <th className='text-dark fs-12'>Url destino</th>
                            <th className='text-dark fs-12'>Cliente</th>
                            <th className='text-dark fs-12'>E-mail</th>
                            <th className='text-dark fs-12'>Ubicación</th>
                            <th className='text-dark fs-12'>Teléfono</th>
                            <th className='text-dark fs-12'>Forma de Pago</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            landings.map((land) => (
                                <tr>
                                    <td>
                                        <span className="fs-12 text-dark">{land.fechaHora}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.landing}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.nombreCompleto}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.email}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.ubicacion}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.telefono}</span>
                                    </td>
                                    <td>
                                        <span className="fs-12 text-dark">{land.formaPago}</span>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
