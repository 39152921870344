import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/Index.css'

export default function HeroPc(props) {
    return (
        <div id={props.classHeroPage} style={{
            backgroundImage: `url('${props.imageUrl}')`,
            backgroundSize: "cover",
            width: "100%",
            backgroundPosition: "top",
            display: "flex !important",
            alignItems: "center !important",
            padding: "2%",
            minHeight: "500px"
        }}>
            <div className="bannerHero">
                <div className='my-4'>
                    <h1 className="fs-2 text-dark fw-bold">{props.important}</h1>
                </div>
                <div className='my-4'>
                    <h1 className="heroTitle">{props.name}</h1>
                </div>
                <div className="my-4 w-80">
                    <span className="text-dark text-start fw-bold fs-5 my-2">{props.description}
                    </span>
                </div>
                <div className='my-4 w-80'>
                    <span className='text-dark text-start fs-6'>{props.alternative}</span>
                </div>
                {
                    props.showButton == true ?
                        <Link className="btn fs-6 btn-more-info my-2" to={props.to}>{props.buttonText}</Link>
                        :
                        ""
                }
            </div>
        </div>
    )
}
