import React, { useEffect, useState } from 'react';
import './styles.css'; // Crea un archivo CSS para el componente
import TarjetaProducto from './TarjetaProducto';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import app from '../../firebase';
import { Link } from 'react-router-dom';
const db = app.firestore();
function ProductosDestacados() {

    const [isLoading, setIsLoading] = useState(true)
    const [equipos, setEquipos] = useState([])


    const destacadosQuery = query(
        collection(db, 'equipos'),
        where('destacado', '==', true),
        orderBy('orden', 'asc')
    );


    const obtenerEquiposDestacados = async () => {
        try {
            const querySnapshot = await getDocs(destacadosQuery);
            const equipos = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setEquipos(equipos);
            setIsLoading(false);
        } catch (error) {
            console.error('Error al obtener equipos destacados:', error);
        }
    };
    useEffect(() => {
        obtenerEquiposDestacados()
    }, [])
    return (
        <section className="productos-destacados">
            <div className="container">
                <h2 className='display-6 text-dark fw-bold'>Nuestros productos destacados</h2>
                {
                    !isLoading ?
                        <div className="row row-cols-3">
                            {equipos.map((equipo, index) => (
                                <div className="col-12 col-md-4">
                                    <TarjetaProducto
                                        key={index}
                                        titulo={equipo.nombre}
                                        descripcion={equipo.descripcion}
                                        imagenUrl={equipo.images && equipo?.images[0]}
                                        enlace={`/equipos/${equipo.identificadorEquipo}`}
                                    />
                                </div>
                            ))}
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-center minHeight-100">
                            <div className="spinner-border text-dark" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }
                <Link to={"/equipos"} className="w-50 btn btn-solicitar-info fs-5 my-4">Ver Todos los Productos ➤</Link>
            </div>
        </section>
    );
}

export default ProductosDestacados;
