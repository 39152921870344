import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './styles.css'
import WaFloatButton from '../../components/WaFloatButton'
import Hero from '../../components/Hero'
import CardBenefit from './CardBenefit'


export default function Nosotros() {

    return (
        <body>
            <main>
                <Header activeLink="nosotros"></Header>

                <WaFloatButton></WaFloatButton>

                <Hero style={"nosotros"} title={"Nosotros"} description={"Nosotros valoramos tu opinión y estamos deseosos de escucharte. Nos interesa conocer más sobre tu proyecto para ofrecerte soluciones de calidad mundial que se adapten perfectamente a tus necesidades. Estamos comprometidos en brindarte resultados excepcionales y superar tus expectativas en cada paso del camino."} urlBackground={"https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fbg-nosotros-coil.png?alt=media&token=f58a5561-73e6-4464-91ce-eb19d2144e23"} />

                <div className="my-4">
                    <div className="d-flex justify-content-center">
                        <h4 className='text-muted fw-bold fs-3 text-center'>En nuestro viaje hacia el éxito, hemos acumulado momentos especiales, desafíos superados y lecciones aprendidas.</h4>
                    </div>
                </div>
                <section className='container-fluid p-4 bg-white' style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLines-background.png?alt=media&token=c2a8117d-18ac-423c-84f0-75a4f85aa5b3)` }}>
                    <div className="container">
                        <div className="row row-cols-2">
                            <div className="col-12 col-md-6">
                                <p className='fs-6 text-start my-4'>
                                    Nuestra empresa nació en 2006 con la visión de
                                    convertirnos en líderes en la comercialización de equipos
                                    industriales y en la prestación de servicios para la
                                    industria. Desde entonces, hemos trabajado arduamente
                                    para cumplir con nuestro compromiso de brindar
                                    soluciones integrales y de alta calidad a nuestros clientes.
                                </p>
                                <p className='fs-6 text-start my-4'>
                                    Desde nuestros inicios, nos hemos enfocado en establecer
                                    relaciones sólidas y duraderas con fabricantes y
                                    proveedores de renombre en la industria. Esto nos ha
                                    permitido ofrecer a nuestros clientes una amplia gama de
                                    equipos industriales de última generación, que cumplen
                                    con los más altos estándares de calidad y tecnología.
                                </p>
                                <p className='fs-6 text-start my-4'>
                                    Nuestro equipo de profesionales altamente capacitados y
                                    con amplia experiencia en el sector industrial, se encarga
                                    de asesorar a nuestros clientes en la selección de los
                                    equipos más adecuados para sus necesidades
                                    específicas. Entendemos que cada industria tiene
                                    requerimientos únicos, por lo que nos esforzamos por
                                    ofrecer soluciones personalizadas que optimicen los
                                    procesos y mejoren la eficiencia de nuestros clientes.
                                </p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="card-100-eficiencia" />
                            </div>

                            <div className="col-12 col-md-6">
                                <CardBenefit description={"Nuestro modelo de negocio se adapta a las necesidades de nuestros clientes con un enfoque especializado y asesoría personalizada."} title={"Modelo de negocios"} icon={<img src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fjob-search-symbol-of-suitcase-and-curriculum-paper-svgrepo-com%20(4)%201.png?alt=media&token=0518f550-a4c4-45f8-9b81-aee4531c50da" />} />
                                <CardBenefit description={"Nos comprometemos con los tiempos de entrega, ajustando el inventario para cubrir la demanda diaria, semanal o mensual."} title={"Tiempos de entrega"} icon={<img src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fclock-icon-card.png?alt=media&token=6518ec35-940a-4e2a-8eeb-1c6bbde35175" />} />
                                <CardBenefit description={"En Coll Instrumentación sa de cv, somos más que proveedores. Estamos aquí para ayudar y orientar con soluciones adecuadas a sus necesidades."} title={"Servicio y atención"} icon={<img src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fcheck-icon-card.png?alt=media&token=a2a8121b-8e44-4a71-b15f-e8bc127e21d8" />} />
                            </div>
                            <div className="col-12 col-md-6">
                                <div className='bg-white'>
                                    <p className='fs-6 text-start my-4'>
                                        Además de la comercialización de equipos industriales, también ofrecemos una amplia gama de servicios para la industria. Nuestro equipo de técnicos especializados se encarga de la instalación, puesta en marcha y mantenimiento de los equipos, garantizando su correcto funcionamiento y prolongando su vida útil. También brindamos servicios de capacitación y asesoría técnica, para asegurar que nuestros clientes obtengan el máximo rendimiento de sus inversiones.
                                    </p>
                                    <p className='fs-6 text-start my-4'>
                                        A lo largo de los años, hemos tenido el privilegio de trabajar con empresas de diversos sectores industriales, desde la manufactura hasta la minería y la energía. Esta experiencia nos ha permitido adquirir un profundo conocimiento de las necesidades y desafíos que enfrenta la industria, lo que nos ha impulsado a seguir innovando y mejorando nuestros productos y servicios.
                                    </p>
                                    <p className='fs-6 text-start my-4'>
                                        En COIL INSTRUMENTACION, nos enorgullece ser reconocidos por nuestra excelencia en el servicio al cliente y por nuestra capacidad para ofrecer soluciones integrales y confiables. Nuestro compromiso es seguir creciendo y evolucionando, para continuar siendo el socio estratégico de confianza de nuestros clientes en el ámbito de los equipos industriales y servicios para la industria.
                                    </p>
                                </div>

                                <a href='/contacto' className="btn btn-dark fs-4 shadow" style={{ borderRadius: "10px" }}> <li className='fa fa-arrow-right'></li> Contáctanos ahora</a>
                            </div>


                        </div>
                    </div>
                </section>

                <Footer></Footer>
            </main>
        </body>
    )
}
