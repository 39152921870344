import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import HeroMobile from '../components/HeroMobile'
import HeroPc from '../components/HeroPc'
import Footer from '../components/Footer'
import FilterResults from 'react-filter-search'
// Firebase
import app from '../firebase';
import WaFloatButton from '../components/WaFloatButton'
const db = app.firestore();

export default function Financiamiento() {


    const navigate = useNavigate()

    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingEquipoData, setIsLoadingEquipoData] = useState(false)
    const [equipoData, setEquipoData] = useState("")
    const [equipos, setEquipos] = useState([])
    const [cart, setCart] = useState([])
    // Filter search

    const [showAgregarArticulo, setShowAgregarArticulo] = useState(false)
    const [isLoadingEquiposSearch, setIsLoadingEquiposSearch] = useState(false)
    const [searchParam, setSearchParam] = useState("")


    const handleChangeEquipoSelect = async (id) => {
        setIsLoadingEquipoData(true)
        await db.collection("equipos").doc(id).get().then((doc) => {
            setEquipoData({ ...doc.data(), id: doc.id })
            setIsLoadingEquipoData(false)
        }).catch((error) => {
            console.log(error)
            alert("No fue posible cargar los equipos intente nuevamente, código de error: ", error)
        })
    }


    // handlers

    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    // Functions
    const addToCart = (equipo) => {
        cart.push({
            "nombreArticulo": equipo.nombre,
            "id": equipo.id
        })
        setShowAgregarArticulo(false)
    }



    const removeFromCart = (equipo) => {
        let filteredCart = cart.filter((e) => {
            return e !== equipo
        })
        setCart(filteredCart)
    }


    const saveSolicitud = () => {
        state["equipoId"] = equipoData.id
        state["equipoNombre"] = equipoData.nombre
        state["adicionales"] = cart
        state["fechaHora"] = timenow()

        db.collection("solicitudesFinanciamiento").add(state).then((docRef) => {
            navigate(`/confirmacion_cotizacion/${docRef.id}`)
        }).catch(() => {
            alert("Error al enviar solicitud")
            window.location.reload()
        })

    }

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    useEffect(() => {
        setIsLoading(true)
        db.collection("equipos").get().then((query) => {
            const docs = []
            query.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setEquipos(docs)
            setIsLoading(false)
        }).catch((error) => {
            alert("No fue posible cargar los equipos intente nuevamente, código de error: ", error)
        })
    }, [])


    return (
        <body>
            <WaFloatButton></WaFloatButton>
            {
                isLoading == true ?

                    <div className="container-fluid align-items-center d-flex justify-content-center minHeight-100">
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    :

                    <main>
                        <Header activeLink="financiamiento"></Header>

                        <div id="pc">
                            <HeroPc
                                showButton={true} to={"/equipos"}
                                important="Financiamiento a tu medida"
                                description="Solicita un plan de financiamiento que se cumpla tus objetivos"
                                alternative="Estamos a tu disposición para ayudarte en cada paso del proceso."
                                classHeroPage="heroPage"
                                imageUrl={"https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/HeroPC-Financiamiento.jpg?alt=media&token=d02588a8-633e-4a2f-a0c0-7e2d2b2db70c"}
                                buttonText="Compra un equipo ➤➤">
                            </HeroPc>
                        </div>
                        <div id="mb">
                            <HeroMobile
                                showButton={true} to={"/equipos"}
                                important="Financiamiento a tu medida"
                                description={"Equipo garantizado y certificado, con planes de financiamiento a tu medida"}
                                classHeroPage="heroPage"
                                imageUrl={"https://images.unsplash.com/photo-1628348068343-c6a848d2b6dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"}
                                buttonText="Compra un equipo ➤➤">
                            </HeroMobile>
                        </div>


                        <div className="container my-6">

                            <div className="my-3 text-center">
                                <h2 className='fs-2 text-dark fw-bold'>¿Cuáles son los pasos?</h2>
                            </div>
                            <div className="row row-cols-4">
                                <div className="col-12 col-md-3">

                                    <div className="d-flex">

                                        <div className="bg-light-blue-icon m-2">
                                            <div className="icon-financiamiento-small"></div>
                                        </div>

                                        <div className=' m-2'>
                                            <div className=''>
                                                <span className='fw-bold'>Solicita tu plan de pagos</span>
                                            </div>
                                            <p className='fs-12 my-2'>Conoce en menos de 2 minutos las opciones que tenemos para ti.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className="d-flex">
                                        <div className="bg-light-blue-icon m-2">
                                            <div className="icon-form-small"></div>
                                        </div>
                                        <div className=' m-2'>
                                            <div className=''>
                                                <span className='fw-bold'>Completa los datos</span>
                                            </div>
                                            <p className='fs-12 my-2'>Envíanos la documentación requerida para recibir tu plan de pagos.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="d-flex">
                                        <div className="bg-light-blue-icon m-2">
                                            <div className="icon-money-small"></div>
                                        </div>
                                        <div className='m-2'>
                                            <div className=''>
                                                <span className='fw-bold'>Realiza el primer pago</span>
                                            </div>
                                            <p className='fs-12 my-2'>Firma el contrato, da el primer pago de tu plan.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="d-flex">
                                        <div className="bg-light-blue-icon m-2">
                                            <div className="icon-calendar-small"></div>
                                        </div>
                                        <div className=' m-2'>
                                            <div className=''>
                                                <span className='fw-bold'>Agenda la entrega</span>
                                            </div>
                                            <p className='fs-12 my-2'>Recibe tu equipo a domicilio, entregamos personalmente.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-cols-2 my-6">
                                <div className="col-12 col-md-6">
                                    <div>
                                        <span className='fw-bold fs-3 text-dark'>Cotizador rápido</span>
                                    </div>
                                    <p>Usa nuestra herramienta para cotizar el equipo que desees</p>
                                    <div className="bg-light-blue d-flex align-items-center p-2 rounded">
                                        <div className="icon-check-small"></div>
                                        <span> &nbsp; Puedes solicitar por este medio tu plan de pagos</span>
                                    </div>

                                    <div className="border rounded p-2 my-4">
                                        <input type="text" onChange={(e) => handleTextChange("nombreCliente", e.target.value)} placeholder='Nombre completo' className="form-control my-4" />
                                        <input type="text" onChange={(e) => handleTextChange("empresa", e.target.value)} placeholder='Empresa o razón social' className="form-control my-4" />
                                        <input type="email" onChange={(e) => handleTextChange("correo", e.target.value)} placeholder='Correo electrónico' className="form-control my-4" />
                                        <input type="text" onChange={(e) => handleTextChange("telefono", e.target.value)} placeholder='Teléfono de contacto' className="form-control my-4" />

                                        <div className="form-group my-4">
                                            <label>Equipo de interés</label>
                                            <select className='form-select' onChange={(e) => handleChangeEquipoSelect(e.target.value)}>

                                                <option value=''>----</option>

                                                {
                                                    equipos.length !== 0 ?

                                                        equipos.map((equipo) => (
                                                            <option value={equipo.id}>{equipo.nombre}</option>
                                                        ))
                                                        :
                                                        <option value={""}>----</option>
                                                }
                                            </select>
                                        </div>

                                        <div className="form-group my-4">
                                            <label>Plazo a meses</label>
                                            <select className='form-select' onChange={(e) => handleTextChange("plazoMeses", e.target.value)}>
                                                <option value="">----</option>
                                                <option value="6 Meses">6 meses</option>
                                                <option value="12 Meses">12 meses</option>
                                                <option value="24 Meses">24 meses</option>
                                            </select>
                                        </div>

                                        <input type="text" placeholder='Enganche inicial' onChange={(e) => handleTextChange("enganche", e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 border">
                                    <div>
                                        <span className='fw-bold fs-3 text-dark'>Detalles de plan</span>
                                    </div>


                                    <div className="border rounded fade-div">
                                        {
                                            isLoadingEquipoData == true ?
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border text-dark" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                :
                                                equipoData !== "" ?
                                                    <div>
                                                        {


                                                            <div className='container fade-div p-3'>
                                                                <div className="row row-cols-auto">
                                                                    <div className="col">
                                                                        <img className='preview-equipo-cotizar' src={equipoData.images[0]} alt="" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="my-2">
                                                                            <span><strong>Equipo:</strong> {equipoData.nombre}</span>
                                                                        </div>

                                                                        <div className="my-2">
                                                                            <span className='fs-6'><strong>Descripcion:</strong> {equipoData.descripcion}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }

                                                        <div className="border p-2">
                                                            {
                                                                cart.length > 0 ?
                                                                    cart.map((equipo) => (
                                                                        <div className='bg-light-blue my-2 p-1 border-light-blue-hover d-flex justify-content-between cs-pointer rounded'>
                                                                            <span>{equipo.nombreArticulo}</span>
                                                                            <button className='btn btn-outline-danger fs-12' onClick={() => removeFromCart(equipo)}>X</button>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <span className='fs-12 fw-bold text-dark'>Sin articulos adicionales</span>
                                                            }
                                                        </div>

                                                        <div className="border p-2">
                                                            <div className="my-2">
                                                                <span className='m-1'>Puedes agregar artículos a la cotización</span>
                                                                <button className='btn btn-outline-dark fs-12' onClick={() => setShowAgregarArticulo(true)}>Agregar artículo</button>
                                                            </div>

                                                            {
                                                                showAgregarArticulo == true ?
                                                                    <>
                                                                        <div className="my-2">
                                                                            <input type="text" placeholder='Buscar' onChange={(e) => setSearchParam(e.target.value)} className="fs-12 form-control" />
                                                                            <div className='d-flex flex-row-reverse'>
                                                                                <button className='btn btn-outline-secondary my-1 fs-12' onClick={() => setShowAgregarArticulo(false)}>Cancelar</button>
                                                                            </div>
                                                                        </div>
                                                                        <FilterResults
                                                                            value={searchParam}
                                                                            data={equipos}
                                                                            renderResults={results => (
                                                                                results.map((equipo, index) => (
                                                                                    <div className="fade-div d-flex rounded p-1 cs-pointer shadow-hover" onClick={() => addToCart(equipo)}>
                                                                                        <div className="my-2">
                                                                                            <img src={equipo.images[0]} className="preview-equipo-cotizar" alt="" srcset="" />
                                                                                            <span className='m-1'>{equipo.nombre}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            )}
                                                                        />
                                                                    </>

                                                                    :
                                                                    <div></div>
                                                            }


                                                        </div>

                                                        <div className="my-4 p-3 border rounded">
                                                            <div className="my-2">
                                                                <span className='text-dark'>Enganche inicial: {state.enganche}</span>
                                                            </div>
                                                            <div className="my-2">
                                                                <span className='text-dark'>Plazo: {state.plazoMeses}</span>
                                                            </div>
                                                            <div className="my-2">
                                                                <span className='text-dark'>Mensualidades de:
                                                                    {
                                                                        state.plazoMeses !== undefined ?

                                                                            <strong>MXN ${(Number(equipoData.precio) / Number(state.plazoMeses.split(" ")[0]))}</strong>
                                                                            :
                                                                            ""
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="my-4 d-flex flex-row-reverse">
                                                                <span className='bg-light-blue text-dark p-2'>Total a crédito</span>
                                                            </div>

                                                            <div className="my-4 d-flex flex-row-reverse">
                                                                <button className='btn btn-dark w-100' onClick={() => saveSolicitud()}>Enviar solicitud</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div>

                                                        <span className='fw-bold fs-6'>Ingresa tus datos para consultar tu plan</span>

                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer></Footer>
                    </main>

            }

        </body>
    )
}
