import React from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
export default function NavbarBack() {

    let navigate = useNavigate()

    const closeSession = () => {
        sessionStorage.clear();
        navigate('/admin/login');
    }
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a href="/admin/backoffice">
                    <div className='logo_container_nav_color_cms'></div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="text-dark nav-link fs-14" href="/admin/backoffice/equipos">Equipos</a>
                        </li>
                        <li className="nav-item">
                            <a className="text-dark nav-link fs-14" href="/admin/backoffice/categorias">Categorías</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="text-dark nav-link fs-14 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Slider
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/admin/backoffice/slider">Listado</a></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="text-dark nav-link fs-14 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Landing pages
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/admin/backoffice/landing/create">Crear</a></li>
                                <li><a className="dropdown-item" href="/admin/backoffice/landing/listado">Listado</a></li>
                                <li><a className="dropdown-item" href="/admin/backoffice/landing/solicitudes">Solicitudes</a></li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <a className="text-dark nav-link fs-14" href="/admin/backoffice/cotizaciones">Cotizaciones</a>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="text-dark nav-link fs-14 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Financiamiento
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/admin/backoffice/financiamiento/solicitudes">Solicitudes</a></li>
                            </ul>
                        </li> */}
                        <li className="nav-item d-flex align-items-center">
                            <button className='btn btn-outline-danger fs-12' onClick={() => closeSession()}>
                                <li className="fa fa-sign-out"></li>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
