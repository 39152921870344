import React, { useState } from 'react';

function TarjetaProducto({ titulo, descripcion, imagenUrl, enlace }) {


    const [showForm, setShowForm] = useState(false)
    const descripcionCortada = descripcion?.length > 100 ? descripcion.substring(0, 160) + '... ver más' : descripcion;

    const [state, setState] = useState(null)

    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const handleSendMessage = () => {
        window.open(`https://api.whatsapp.com/send?phone=+527298060042&text=Cotizar producto: *${titulo}*, Mensaje: ${state?.mensaje}, Nombre: ${state?.nombreCliente}`)
        gtag_report_conversion()
    }

    function gtag_report_conversion(url) {

        const gtag = window.gtag;

        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };

        //  Event snippet for Clic producto WhatsApp conversion page 
        if (gtag) {
            gtag('event', 'conversion', {
                'send_to': 'AW-11344795049/wY0OCLnOw-4YEKmrz6Eq',
                'value': 1.0,
                'currency': 'MXN',
                'transaction_id': '',
                'event_callback': callback
            });
        } else {
            console.log("GTAG NO ESTA DISPONIBLE")
        }

        return false;
    }


    return (
        <div className='bg-white p-2 my-2 h-card-producto'>
            {
                !showForm ?
                    <div>
                        <div>
                            <img className='w-100 img-card-equipo' src={imagenUrl} alt={titulo} title={titulo} />
                        </div>

                        <div className="my-2">
                            <h1 className='fw-bold fs-5 text-start'>
                                {titulo}
                            </h1>
                            <p className="small my-2 text-start">{descripcionCortada}</p>
                        </div>

                        <div className="my-2">
                            <button className='btn btn-primary' onClick={() => setShowForm(!showForm)}>
                                <div className='d-flex align-items-center justify-content-center w-100'>
                                    <span className='fs-4 fs-bold px-2'>Solicitar cotización</span>
                                    <i className="fa fa-commenting"></i>
                                </div>
                            </button>
                        </div>
                    </div>
                    :

                    <div className="fade-div p-2">

                        <div class="alert alert-success" role="alert">
                            Cotiza tu producto enviando un mensaje, te daremos una respuesta inmediata
                        </div>
                        <div className="form-group my-4 text-start">
                            <label className='fs-5 my-2'>Nombre completo</label>
                            <input onChange={(e) => handleTextChange("nombreCliente", e.target.value)} type="text" className="form-control" placeholder='Escriba su nombre completo' />
                        </div>
                        <div className="form-group my-4 text-start">
                            <label className='fs-5 my-2'>Mensaje</label>
                            <input onChange={(e) => handleTextChange("mensaje", e.target.value)} type="text" className="form-control" placeholder='Escriba su mensaje' />
                        </div>

                        <div className="my-2">
                            <a class="btn card-button-wa" target="_blank">
                                <div className='d-flex align-items-center justify-content-center w-100'>
                                    <i className="fa fa-whatsapp"></i>
                                    <span className='fs-6 fs-bold px-2' onClick={handleSendMessage}>Chatear en WhatsApp</span>
                                </div>
                            </a>
                        </div>
                        <div>
                            <button onClick={() => setShowForm(!showForm)} className="btn btn-secondary text-underline fs-6">
                                Cancelar
                            </button>
                        </div>
                    </div>
            }
        </div>
    );
}

export default TarjetaProducto;
