import React from 'react'

export default function CardBenefit({ icon, title, description }) {
    return (
        <div className='shadow bg-white p-3 my-4' style={{ borderRadius: '20px' }}>
            {
                icon
            }

            <div className="my-3">
                <span className='fw-bold fs-5'>{title}</span>
            </div>

            <div className="my-3 text-start">
                <span className='text-muted text-start fs-6'>{description}</span>
            </div>
        </div>
    )
}
